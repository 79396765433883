<template>
  <!-- document & number -->
  <div class="form-group">
    <!-- document -->
    <DropdownField
      :options="options.documentType"
      :label="$translations.form['document-type']"
      v-model="form.document_type"
      :mobile-options="true"
    />

    <!-- number -->
    <InputMaskField
      mask="##############"
      :label="$translations.form['document-number']"
      v-model="form.document"
    />
  </div>
</template>

<script>
import { InputMaskField, DropdownField } from '@seliaco/sea-otter'

export default {
  name: 'CobreForm',
  components: {
    DropdownField,
    InputMaskField
  },
  data () {
    return {
      loading: false,
      form: {
        type: 'COBRE',
        document: null,
        document_type: 'CC'
      },
      options: {
        documentType: [
          {
            label: 'Cédula Ciudadanía',
            value: 'CC'
          },
          {
            label: 'Cédula Extranjería',
            value: 'CE'
          },
          {
            label: 'NIT',
            value: 'NIT'
          }
        ]
      }
    }
  },
  methods: {
    validForm () {
      return Boolean(
        this.form.document &&
        this.form.document_type
      )
    }
  },
  watch: {
    form: {
      handler () {
        const valid = this.validForm()
        if (valid) {
          this.$emit('form', this.form)
        } else {
          this.$emit('form', null)
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="sass" scoped>
.form-group
  display: flex
  gap: 8px
  margin-top: 16px
</style>
