<template>
  <div>
    <div @click="onToggleCoupon">
      <Heading
        :text="$translations.checkout.coupon"
        :action="applied ? null : actions.toggleCoupon"
      />
    </div>

    <div class="margin-top" v-if="!collapsed.coupon && !loadingCheckout">
      <!-- result -->
      <div class="check-out-coupon-result" v-if="applied">
        <div class="check-out-coupon-result-title">
          {{ coupon }}
        </div>

        <div class="check-out-coupon-result-description">
          {{ description }}
        </div>

        <div class="icon-close" @click="clear" />
      </div>

      <!-- form -->
      <div class="check-out-coupon" v-else>
        <InputTextField
          v-model="coupon"
          :label="$translations.promos.coupons.enter"
        />

        <Button
          :disable="!isActive"
          :text="$translations.promos.coupons.apply"
          :loading="loading"
          @clicked="submit"
        />
      </div>
    </div>

    <div class="info-box margin-top" v-if="showCouponInfoBox">
      <div class="info-box-icon icon-pricetag-on icon-display text-green-80"/>
      <div class="info-box-title">
        <div
          class="content-small text-green-120"
          v-text="$translations.checkout.coupons.title.replaceAll('{NAME}', couponDetail.meta.name)"
        />
        <div
          class="info-box-description body-small text-green-120"
          v-text="couponDetail.meta.description"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  Button,
  Promos,
  Heading,
  Settings,
  CheckOutV2
} from '@seliaco/red-panda'
import { Coupons } from '@/types/events'
import { InputTextField } from '@seliaco/sea-otter'

export default {
  name: 'CheckOutCoupon',
  components: {
    Button,
    Heading,
    InputTextField
  },
  props: {
    id: String,
    type: String,
    loadingCheckout: Boolean,
    initialValue: String,
    checkOutVersion: Number
  },
  data () {
    return {
      coupon: null,
      description: null,
      applied: false,
      loading: false,
      actions: {
        toggleCoupon: {
          text: this.$translations.general.add,
          callback: () => null
        }
      },
      collapsed: {
        coupon: true
      },
      couponDetail: null
    }
  },
  mounted () {
    this.getCouponViewOption()
  },
  methods: {
    submit () {
      this.loading = true
      const coupon = this.coupon.toUpperCase()
      let service = null

      Coupons.applyCouponClick({
        coupon,
        user: this.$store.getters['auth/user']
      })

      if (this.checkOutVersion === 2) {
        service = () => CheckOutV2.addCoupon({
          product_type: this.type,
          code: coupon
        })
      } else if (this.type === 'APPOINTMENT') {
        service = () => Promos.Coupons.validateForAppointment(this.id, coupon)
      } else if (this.type === 'PACKAGE') {
        service = () => Promos.Coupons.validateForPackage(this.id, coupon)
      } else {
        return
      }

      service()
        .then((response) => {
          this.couponDetail = response.invoice.details.find((val) => val.type === 'COUPON')
          this.$emit('invoice', {
            invoice: response.invoice,
            coupon,
            promocodeId: response.promocode_id
          })

          Coupons.applyCouponSuccessful({
            coupon,
            user: this.$store.getters['auth/user'],
            data: response
          })

          if (this.checkOutVersion === 2) {
            const discount = response.invoice.details.find(o => o.type === 'COUPON')
            this.description = discount.name
          } else {
            this.description = response.description
          }

          this.applied = true
          this.collapsed.coupon = false
        })
        .catch((error) => {
          const errorMessage =
            error.message ||
            this.$translations.toast.messages.error['invalid-coupon']

          this.$toast({
            severity: 'error',
            text: errorMessage
          })

          Coupons.applyCouponFailed({
            coupon,
            user: this.$store.getters['auth/user'],
            error: errorMessage
          })

          this.coupon = null
        })
        .finally(() => {
          this.loading = false
        })
    },
    async clear () {
      this.coupon = null
      this.applied = false
      this.$emit('clear')

      if (this.checkOutVersion === 2) {
        await CheckOutV2.addCoupon({
          product_type: this.type,
          code: null
        })
      }
    },
    onToggleCoupon (event) {
      if (this.applied) {
        return
      }
      if (this.collapsed.coupon && event) {
        Coupons.addCouponClick({
          user: this.$store.getters['auth/user'],
          opcion: this.$translations.general.add
        })
      } else if (!this.collapsed.coupon && event) {
        Coupons.closeCouponClick({
          user: this.$store.getters['auth/user'],
          opcion: this.$translations.general.close
        })
      }
      this.actions.toggleCoupon.text =
        this.$translations.general[this.collapsed.coupon ? 'close' : 'add']
      this.collapsed.coupon = !this.collapsed.coupon
    },
    getCouponViewOption () {
      Settings.read({
        columns: 'value',
        eq: {
          key: 'OPEN_COUPON_SECTION'
        }
      }).then((response) => {
        if (response?.data[0]?.value === 'true') {
          this.onToggleCoupon()
        }
      })
    }
  },
  computed: {
    isActive () {
      return Boolean(this.coupon) && !this.loading
    },
    isUppercase () {
      return Boolean(this.coupon && this.coupon.length > 0)
    },
    showCouponInfoBox () {
      const reward = this.couponDetail?.meta?.rewards?.find(val => (val.type === 'CREATE_PROMOCODE' || val.type === 'CASHBACK'))
      return Boolean(reward) && this.applied
    }
  },
  watch: {
    initialValue: {
      handler (initialValue) {
        if (initialValue && initialValue !== this.coupon) {
          this.coupon = initialValue
          this.submit()

          if (!initialValue) {
            this.onToggleCoupon()
          }
        }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="sass" scoped>
.check-out-coupon
  display: grid
  grid-template-columns: 1fr min-content
  gap: 16px
  align-items: center

.input
  border: 1px solid #a6a6a6 !important
  padding: 12px !important
  border-radius: 8px !important
  width: 100%
  height: 46px !important
  max-height: 46px !important

  &.active
    color: var(--gray-80)
::v-deep .field-control
  text-transform: uppercase

.check-out-coupon-result
  display: grid
  grid-template-columns: 1fr min-content
  gap: 4px 16px

  &-title,
  &-description
    grid-column: 1
    font-size: var(--tiny)

  &-title
    font-weight: 600
    color: var(--gray-80)

  &-description
    color: var(--gray-60)

  .icon-close
    cursor: pointer
    grid-column: 2
    grid-row: 1 / 3
    font-size: var(--xl)
    align-self: start

.info-box
  display: flex
  gap: 8px
  padding: 16px
  border-radius: 8px
  border: 1px solid var(--green-40)
  background: var(--green-20)
  &-title
    display: flex
    flex-direction: column
    gap: 4px
</style>
