<template>
  <div>
    <!-- name -->
    <div class="form-field">
      <div class="margin-top-xs">
        <Checkbox
          :text="$translations.form['use-phone']"
          type="fullcheck"
          :checked="validPhone"
          @value="changePhone($event)"
        />
      </div>
    </div>

    <!-- phone -->
    <PhoneField
      class="margin-top"
      v-model="form"
      :placeholder-phone="$translations.form['phone-nequi']"
      :mobile-mode="true"
      :value="{ dialCode: form.dialCode, phone: form.phone, country: 'CO' }"
      :disabled-country="true"
    />
  </div>
</template>

<script>
import { Checkbox } from '@seliaco/red-panda'
import { PhoneField } from '@seliaco/sea-otter'

export default {
  name: 'NequiForm',
  components: {
    PhoneField,
    Checkbox
  },
  data () {
    return {
      form: {
        dialCode: '57',
        phone: ''
      },
      validPhone: true
    }
  },
  mounted () {
    this.changePhone({ isChecked: true })
  },
  methods: {
    changePhone (val) {
      const user = this.$store.getters['auth/user']
      this.form.phone = val.isChecked ? `${user.phone}` : ''
    }
  },
  watch: {
    form: {
      handler (val) {
        if (val.phone) {
          this.$emit('form', { ...this.form, value: 'NEQUI' })
        } else {
          this.$emit('form', null)
        }
      },
      deep: true
    }
  }
}
</script>
