<template>
  <Carrousel
    v-if="items && items.length"
    section-name="REVIEWS"
    :title="$translations.checkout.reviews.title"
    :header-title="$translations.general['see-more']"
    :items="items"
    :loading="loading"
    :has-page-content="true"
    :show-header-action="false"
  />
</template>

<script>
import { Reviews, Carrousel } from '@seliaco/red-panda'
import ReviewCard from '@/views/specialist/components/ReviewCard'
export default {
  name: 'CheckoutReviews',
  components: { Carrousel },
  data () {
    return {
      page: 1,
      perPage: 20,
      items: null,
      loading: false
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      this.loading = true
      if (this.$route?.query?.specialistId) {
        Reviews.getPaginateReviews(this.$route.query.specialistId, this.page, this.perPage, {
          excludeWithContent: 'true',
          excludeByStars: 4
        })
          .then((response) => {
            this.mapCardProps(response.data)
          })
          .catch(() => {
            this.$toast({
              severity: 'error',
              text: this.$translations['specialist-page'].errors['get-reviews']
            })
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    mapCardProps (values) {
      this.items = values.map(val => {
        return {
          component: ReviewCard,
          show: true,
          props: { review: val }
        }
      })
    }
  }
}
</script>
