<template>
  <div class="specialist-appointment-card">
    <!-- specialist image -->
    <img class="specialist-appointment-card-pic" :src="avatar" alt="pic" />

    <!-- specialist information -->
    <div class="specialist-appointment-card-content">
      <div
        class="specialist-appointment-card-content_title heading-tag"
        v-text="title"
      />
      <div class="body-small" v-if="subtitle" v-text="subtitle" />
      <div class="body-small" v-if="description" v-text="description" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SpecialistAppointmentCard',
  props: {
    picture: String,
    title: String,
    description: String,
    subtitle: String,
    border: {
      type: Boolean,
      value: true
    }
  },
  computed: {
    avatar () {
      return this.picture || require('@/assets/images/avatar.png')
    }
  }
}
</script>
<style lang="sass" scoped>
.specialist-appointment-card
  background: var(--gray-5)
  border-radius: 16px
  padding: 16px
  display: flex
  gap: 8px
  &-pic
    width: 60px
    height: 60px
    border-radius: 50%
  &-content
    display: flex
    flex-direction: column
    &_title
      margin-bottom: 2px
</style>
