<template>
  <div class="form-field" v-if="currency === 'COP'">
    <InputNumberField
      id="installments"
      v-model="form.installments"
      :label="$translations.form.installments"
      :min="1"
      :max="24"
    />
  </div>
</template>

<script>
import { InputNumberField } from '@seliaco/sea-otter'

export default {
  name: 'CardForm',
  props: {
    card: String,
    currency: String
  },
  components: {
    InputNumberField
  },
  data () {
    return {
      form: {
        installments: '1'
      }
    }
  },
  watch: {
    card: {
      handler () {
        this.$emit('form', { ...this.form, id: this.card, value: 'CARD' })
      },
      immediate: true
    },
    form: {
      handler () {
        this.$emit('form', { ...this.form, id: this.card, value: 'CARD' })
      },
      immediate: true,
      deep: true
    }
  }
}
</script>
