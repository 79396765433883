<template>
  <div class="margin-top">
    <!-- add card -->
    <ListItem
      icon="icon-creditcard-off"
      :text="$translations.checkout['add-card']"
      type="ARROW"
      @clicked="goToAddCard"
    />

    <!-- payment methods -->
    <div class="margin-top-xs">
      <List
        :options="options"
        type="SELECT"
        @clicked="handlePaymentMethodSelected"
        ref="paymentMethodsList"
      />
    </div>

    <!-- form -->
    <div class="payment-method-form" id="form">
      <PseForm @form="handleFormFilled" v-if="currentPaymentMethod === 'PSE'" />
      <CobreForm
        @form="handleFormFilled"
        v-if="currentPaymentMethod === 'COBRE'"
      />
      <NequiForm
        @form="handleFormFilled"
        v-if="currentPaymentMethod === 'NEQUI'"
      />
      <CardForm
        @form="handleFormFilled"
        v-if="currentPaymentMethod === 'CARD'"
        :currency="currency"
        :card="currentPaymentMethodId"
      />
      <LoanForm
        @form="handleFormFilled"
        v-if="currentPaymentMethod === 'ADDI'"
      />
      <PayLaterForm
        @form="handleFormFilled"
        v-if="currentPaymentMethod === 'BANCOLOMBIA_BNPL'"
      />
    </div>
  </div>
</template>

<script>
import { ListItem, List } from '@seliaco/red-panda'
import { PaymentEvent } from '@/types/events'
import PseForm from './PseForm'
import LoanForm from './LoanForm'
import NequiForm from './NequiForm'
import CardForm from './CardForm'
import CobreForm from './CobreForm'
import PayLaterForm from './PayLaterForm'

export default {
  name: 'CheckOutPaymentMethod',
  components: {
    CobreForm,
    ListItem,
    List,
    PseForm,
    LoanForm,
    NequiForm,
    CardForm,
    PayLaterForm
  },
  props: {
    paymentMethods: Array,
    loading: Boolean,
    currency: String
  },
  data () {
    return {
      currentPaymentMethod: null,
      currentPaymentMethodId: null
    }
  },
  mounted () {
    if (this.$route.query && this.$route.query.paymentMethodType) {
      this.handleAutoSelectPaymentMethod(this.$route.query.paymentMethodType)
    }
  },
  methods: {
    goToAddCard () {
      this.$emit('go-add-card')
    },
    handleAutoSelectPaymentMethod (value) {
      const index = this.paymentMethods.findIndex((o) => {
        if (o.type === value) {
          if (o.type === 'CARD') {
            return o.id === this.$route.query.paymentMethodId
          } else {
            return o
          }
        }
      })

      if (index !== 0) {
        setTimeout(() => {
          this.$refs.paymentMethodsList.handleItemSelect(
            this.paymentMethods[index],
            index
          )
        }, 1000)
      }
    },
    handleFormFilled (form) {
      if (form) {
        this.$emit('payment-method', {
          ...form,
          type: this.currentPaymentMethod
        })
      } else {
        this.$emit('payment-method', {
          type: this.currentPaymentMethod
        })
      }
    },
    handlePaymentMethodSelected (paymentMethod) {
      this.currentPaymentMethod = paymentMethod?.type
      switch (paymentMethod?.type) {
        case 'PSE':
        case 'ADDI':
        case 'NEQUI':
        case 'COBRE':
        case 'BANCOLOMBIA_BNPL':
          this.initForm()
          break
        case 'CARD':
          this.initForm()
          this.currentPaymentMethodId = paymentMethod.id
          break
        case 'BANCOLOMBIA_TRANSFER':
          this.$emit('payment-method', paymentMethod)
          break
      }
      PaymentEvent.selectPaymentMethod({
        type: this.currentPaymentMethod || 'CARD',
        user: this.$store.getters['auth/user']
      })
    },
    initForm () {
      setTimeout(() => {
        document.getElementById('form').scrollIntoView({
          behavior: 'smooth'
        })
      }, 100)

      this.$emit('payment-method', { type: this.currentPaymentMethod })
    }
  },
  computed: {
    options () {
      if (!this.paymentMethods || !this.paymentMethods.length) {
        return []
      }

      return this.paymentMethods.map((o) => {
        // TODO: remove when illustration exist
        if (o?.type !== 'COBRE') {
          return {
            ...o,
            text: o.value,
            illustration: o.brand || o.type
          }
        } else {
          return {
            ...o,
            text: o.value,
            icon: 'icon-wallet'
          }
        }
      })
    }
  },
  watch: {
    $route (route) {
      if (route.query && route.query.card) {
        this.handleAutoSelectPaymentMethod(this.$route.query.card)
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.payment-method-form
  border-top: 1px solid var(--gray-10)
  padding-top: 16px
  margin-top: 16px
</style>
