import { Transactions } from '@seliaco/red-panda'

export const submitWithFullPromotion = (
  productId,
  productType,
  promotionId,
  onSuccess,
  onError
) => {
  if (productType === 'APPOINTMENT') {
    productType = 'SERVICE'
  }

  Transactions.createWithFullPromotion({
    product_id: productId,
    product_type: productType,
    promotion_id: promotionId
  })
    .then(() => onSuccess(null))
    .catch(() => onError())
}
