import { Transactions } from '@seliaco/red-panda'

const resolveBody = (
  userId,
  checkOut
) => {
  return {
    user_id: userId,
    appointment_id: checkOut.id,
    user_package_id: checkOut.userPackage.id
  }
}

export const submitWithPackage = (
  userId,
  checkOut,
  onSuccess,
  onError
) => {
  const body = resolveBody(
    userId,
    checkOut
  )

  Transactions.createWithPackage(body)
    .then(() => onSuccess(null))
    .catch(() => onError())
}
