<template>
  <div>
    <Heading :text="$translations.checkout['selia-business']" />

    <div class="margin-top">
      <PersonCard
        :image="benefit.company.logo"
        :title="benefit.company.name"
        :middle-line="benefit.benefit"
      />
    </div>
  </div>
</template>

<script>
import { Heading, PersonCard } from '@seliaco/red-panda'

export default {
  name: 'CheckOutBenefit',
  components: {
    Heading,
    PersonCard
  },
  props: {
    benefit: Object
  }
}
</script>
