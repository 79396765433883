import { Transactions } from '@seliaco/red-panda'
import { Browser } from '@capacitor/browser'

export const submitWithLoan = (
  userId,
  checkOut,
  paymentMethod,
  couponCode = null,
  currentPlatform,
  onSuccess,
  onError
) => {
  const body = {
    user_id: userId,
    identification_type: 'CC',
    identification_number: paymentMethod.identification_number,
    phone: paymentMethod.phone,
    dial_code: paymentMethod.dialCode,
    place_id: paymentMethod.place_id,
    id: checkOut.id,
    coupon_code: couponCode,
    type: checkOut.type
  }

  Transactions.createWithLoan(body)
    .then((response) => {
      Browser.open({ url: response.redirect })
        .then(() => {
          if (currentPlatform === 'web') {
            onSuccess(response.transaction_id)
          } else {
            Browser.addListener('browserFinished', () => onSuccess(response.transaction_id))
          }
        })
        .catch(() => onError())
    })
    .catch(() => onError())
}
