var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.currentModal)?_c('FullScreenDialog',{attrs:{"title":_vm.modal[_vm.currentModal].title,"subtitle":_vm.loadSubtitle,"illustration":_vm.modal[_vm.currentModal].illustration,"show-icon-loader":_vm.modal[_vm.currentModal].loading,"background-style":_vm.modal[_vm.currentModal].backgroundStyle,"canBeClosed":_vm.canCloseModal,"backdrop-closes":_vm.canCloseBackdrop,"icon":_vm.modal[_vm.currentModal].icon,"iconColor":_vm.modal[_vm.currentModal].iconColor},on:{"close":_vm.close},scopedSlots:_vm._u([{key:"body",fn:function(){return [(_vm.currentModal === 'ERROR')?[_c('div',{staticClass:"space-y-2"},[_c('Button',{attrs:{"text":_vm.$translations.checkout.modals.error.confirm},on:{"clicked":function($event){return _vm.handleActionEvent('go-checkout')}}})],1)]:_vm._e(),(_vm.currentModal === 'ERROR_EXPIRED')?[_c('div',{staticClass:"space-y-2"},[_c('Button',{attrs:{"text":_vm.$translations.checkout.modals['error-expired'].confirm},on:{"clicked":function($event){return _vm.handleActionEvent('create-appointment')}}})],1)]:_vm._e(),(
          _vm.currentModal === 'SUCCESS_APPOINTMENT' ||
          _vm.currentModal === 'SUCCESS_PACKAGE' ||
          _vm.currentModal === 'SUCCESS_CONFERENCE'
        )?[_c('div',{staticClass:"space-y-2"},[_c('div',{staticClass:"margin-bottom"},[(_vm.specialist && _vm.currentModal === 'SUCCESS_PACKAGE')?_c('SpecialistAppointmentCard',{attrs:{"picture":_vm.specialist.picture,"title":_vm.specialist.name,"subtitle":_vm.specialist.type.name,"description":_vm.specialist.address}}):_vm._e(),(_vm.currentModal === 'SUCCESS_APPOINTMENT')?_c('AppointmentCard',{directives:[{name:"selectable-container",rawName:"v-selectable-container",value:({
                callback: _vm.navigateSpecialistProfile,
                elementLevel: 1
              }),expression:"{\n                callback: navigateSpecialistProfile,\n                elementLevel: 1\n              }"}],attrs:{"image":_vm.checkOut.appointment.specialist.picture,"title":_vm.checkOut.appointment.specialist.name,"middle-line":_vm.specialistCardDate,"bottom-line":_vm.specialistCardService,"service-icon":_vm.checkOut.appointment.service.icon_name,"arrow-action":{ callback: () => true }}}):_vm._e()],1),(
              _vm.currentModal === 'SUCCESS_APPOINTMENT' ||
              _vm.currentModal === 'SUCCESS_CONFERENCE'
            )?[_c('Button',{attrs:{"text":_vm.$translations.checkout.modals['success-appointment'].primary},on:{"clicked":function($event){return _vm.handleActionEvent('recommendations')}}}),_c('Button',{attrs:{"text":_vm.$translations.checkout.modals['success-appointment'].secondary,"type":"outline"},on:{"clicked":function($event){return _vm.handleActionEvent('go-home')}}})]:_vm._e(),(_vm.currentModal === 'SUCCESS_PACKAGE')?[(_vm.specialist)?_c('Button',{staticClass:"margin-bottom",attrs:{"text":_vm.$translations.checkout.modals['success-package'].primary},on:{"clicked":function($event){return _vm.handleActionEvent('create-appointment')}}}):_vm._e(),(_vm.specialist)?_c('Button',{attrs:{"text":_vm.$translations.checkout.modals['success-package'].secondary,"type":"outline"},on:{"clicked":function($event){return _vm.handleActionEvent('go-home')}}}):_vm._e(),(!_vm.specialist)?_c('Button',{attrs:{"text":_vm.$translations.checkout.modals['success-generic-package']
                  .primary},on:{"clicked":function($event){return _vm.handleActionEvent('available-specialists')}}}):_vm._e(),(!_vm.specialist)?_c('Button',{attrs:{"text":_vm.$translations.checkout.modals['success-generic-package']
                  .secondary,"type":"outline"},on:{"clicked":function($event){return _vm.handleActionEvent('go-home')}}}):_vm._e()]:_vm._e()],2)]:_vm._e(),(_vm.currentModal === 'SUCCESS_PROGRAM')?[_c('div',{staticClass:"space-y-2"},[_c('Button',{staticClass:"margin-bottom",attrs:{"text":_vm.$translations.checkout.modals['success-program'].primary},on:{"clicked":function($event){return _vm.handleActionEvent('choose-specialist')}}}),_c('Button',{attrs:{"text":_vm.$translations.checkout.modals['success-program'].secondary,"type":"outline"},on:{"clicked":function($event){return _vm.handleActionEvent('go-home')}}})],1)]:_vm._e(),(
          _vm.currentModal === 'SUCCESS_APPOINTMENT_NUTRITION' ||
          _vm.currentModal === 'SUCCESS_PACKAGE_NUTRITION'
        )?[_c('div',{staticClass:"space-y-2"},[(_vm.currentModal === 'SUCCESS_APPOINTMENT_NUTRITION')?[_c('Button',{attrs:{"text":_vm.$translations.checkout.modals['success-appointment-nutrition']
                  .primary},on:{"clicked":function($event){return _vm.handleActionEvent('go-nutrition-form')}}}),_c('Button',{attrs:{"text":_vm.$translations.checkout.modals['success-appointment-nutrition']
                  .secondary,"type":"outline"},on:{"clicked":function($event){return _vm.handleActionEvent('recommendations')}}}),_c('Button',{attrs:{"text":_vm.$translations.checkout.modals['success-appointment-nutrition']
                  .third,"type":"outline"},on:{"clicked":function($event){return _vm.handleActionEvent('go-home')}}})]:_vm._e(),(_vm.currentModal === 'SUCCESS_PACKAGE_NUTRITION')?[_c('Button',{attrs:{"text":_vm.$translations.checkout.modals['success-appointment-nutrition']
                  .primary},on:{"clicked":function($event){return _vm.handleActionEvent('go-nutrition-form')}}}),_c('Button',{attrs:{"text":_vm.$translations.checkout.modals['success-appointment-nutrition']
                  .secondary,"type":"outline"},on:{"clicked":function($event){return _vm.handleActionEvent('go-home')}}})]:_vm._e()],2)]:_vm._e(),(_vm.currentModal === 'CANCEL_APPOINTMENT')?[_c('div',{staticStyle:{"display":"flex","flex-direction":"column","gap":"8px"}},[_c('Button',{attrs:{"text":_vm.$translations.checkout.modals.cancel.confirm,"loading":_vm.loading,"disable":_vm.editLoading,"color":"red"},on:{"clicked":_vm.cancelAppointmentV2}}),_c('Button',{attrs:{"text":_vm.$translations.checkout.modals.cancel.return,"disable":_vm.loading || _vm.editLoading,"type":"outline","color":"gray"},on:{"clicked":function($event){return _vm.handleActionEvent('go-checkout')}}}),_c('Button',{staticStyle:{"color":"var(--red)"},attrs:{"text":_vm.$translations.checkout.modals.cancel.edit,"disable":_vm.loading,"loading":_vm.editLoading,"type":"link","color":"red"},on:{"clicked":_vm.editAppointment}})],1)]:_vm._e(),(_vm.currentModal === 'CANCEL_CONFERENCE')?[_c('div',{staticClass:"space-y-2"},[_c('Button',{attrs:{"text":_vm.$translations.checkout.modals['cancel-conference'].confirm,"loading":_vm.loading,"color":"red"},on:{"clicked":_vm.cancelConference}}),_c('Button',{attrs:{"text":_vm.$translations.checkout.modals['cancel-conference'].cancel,"disable":_vm.loading,"type":"outline","color":"gray"},on:{"clicked":function($event){return _vm.handleActionEvent('go-checkout')}}})],1)]:_vm._e(),(_vm.currentModal === 'TIMEOUT_CONFERENCE')?[_c('div',{staticClass:"space-y-2"},[_c('Button',{attrs:{"text":_vm.$translations.checkout.modals['timeout-conference'].confirm,"loading":_vm.loading,"color":"red"},on:{"clicked":function($event){return _vm.$emit('reset')}}}),_c('Button',{attrs:{"text":_vm.$translations.general.close,"disable":_vm.loading,"type":"outline","color":"gray"},on:{"clicked":_vm.cancelConference}})],1)]:_vm._e()]},proxy:true}],null,false,3472811749)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }