<template>
  <div>
    <!-- document & number -->
    <div class="form-group">
      <InputTextField
        class="margin-top"
        :label="$translations.form['first-name']"
        v-model="form.name"
      />

      <InputTextField
        class="margin-top"
        :label="$translations.form['last-name']"
        v-model="form.last_name"
      />
    </div>

    <!-- document & number -->
    <div class="form-group">
      <!-- document -->
      <DropdownField
        :options="options.documentType"
        :label="$translations.form['document-type']"
        v-model="form.user_legal_id_type"
        :mobile-options="true"
      />

      <!-- number -->
      <InputMaskField
        mask="##############"
        :label="$translations.form['document-number']"
        v-model="form.user_legal_id"
      />
    </div>

    <!-- phone -->
    <PhoneField
      class="margin-top"
      v-model="formPhone"
      :placeholder-phone="$translations.form['phone']"
      :mobile-mode="true"
      :value="{
        dialCode: formPhone.dialCode,
        phone: formPhone.phone,
        country: 'CO'
      }"
      :disabled-country="true"
    />
  </div>
</template>

<script>
import {
  InputTextField,
  DropdownField,
  PhoneField,
  InputMaskField
} from '@seliaco/sea-otter'
import { mapGetters } from 'vuex'

export default {
  name: 'PayLaterForm',
  components: {
    DropdownField,
    InputTextField,
    PhoneField,
    InputMaskField
  },
  data () {
    return {
      loading: false,
      form: {
        type: 'BANCOLOMBIA_BNPL',
        name: null,
        last_name: null,
        user_legal_id_type: null,
        user_legal_id: null,
        dialCode: '+57',
        phone: null,
        country: 'CO'
      },
      formPhone: {
        dialCode: '+57',
        phone: null,
        country: 'CO'
      },
      options: {
        documentType: [
          {
            label: 'Cédula Ciudadanía',
            value: 'CC'
          },
          /*{
            label: 'Cédula Extranjería',
            value: 'CE'
          },
          {
            label: 'NIT',
            value: 'NIT'
          }*/
        ]
      }
    }
  },
  created () {
    this.form = {
      ...this.form,
      name: this.user.first_name,
      last_name: this.user.last_name,
      dialCode: this.user.dial_code,
      phone: this.user.phone,
      country: this.user.country
    }

    this.formPhone = {
      ...this.formPhone,
      dialCode: this.user.dial_code,
      phone: this.user.phone,
      country: this.user.country
    }
  },
  methods: {
    validForm () {
      return Boolean(
        this.form.user_legal_id_type &&
          this.form.user_legal_id &&
          this.form.name &&
          this.form.last_name &&
          this.form.phone &&
          this.form.dialCode &&
          this.form.country
      )
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user'
    })
  },
  watch: {
    formPhone: {
      handler () {
        this.form = {
          ...this.form,
          phone: this.formPhone.phone,
          dialCode: this.formPhone.dialCode,
          country: this.formPhone.country
        }
      },
      deep: true
    },
    form: {
      handler () {
        const valid = this.validForm()
        if (valid) {
          this.$emit('form', this.form)
        } else {
          this.$emit('form', null)
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="sass" scoped>
.form-group
  display: flex
  gap: 8px
  margin-top: 16px
</style>
