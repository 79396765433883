<template>
  <div>
    <Heading :text="$translations.checkout['my-packages']" />

    <div class="margin-top">
      <ListItem
        :text="text"
        icon="icon-appointment-on"
        icon-background-color="purple"
        type="SELECT"
        :active="true"
      />
    </div>
  </div>
</template>

<script>
import { Heading, ListItem } from '@seliaco/red-panda'

export default {
  name: 'CheckOutPackage',
  components: {
    Heading,
    ListItem
  },
  props: {
    package: Object
  },
  computed: {
    text () {
      const available = this.$translations.checkout.available

      return `${this.package.remaining_uses} / ${this.package.max_uses} ${available}`
    }
  }
}
</script>
