import { Transactions } from '@seliaco/red-panda'
import { getUrl } from '@/views/check-out/services/payment-gateway-helper'

const resolveBody = (
  userId,
  checkOut,
  paymentMethod,
  couponCode = null
) => {
  let body = {
    user_id: userId,
    coupon_code: couponCode,
    type: paymentMethod.type,
    payment_method: {
      payment_description: checkOut.payment_description
    }
  }

  switch (checkOut.type) {
    case 'APPOINTMENT':
      body = {
        ...body,
        appointment: {
          id: checkOut.id,
          specialist: checkOut.appointment.specialist.id
        }
      }
      break
    case 'PACKAGE':
      body = {
        ...body,
        package_id: checkOut.id
      }
      break
    case 'CONFERENCE':
      body = {
        ...body,
        conference_id: checkOut.conferenceData.conference.id,
        quota_id: checkOut.conferenceData.quota.id
      }
      break
  }

  return body
}

export const submitWithBancolombia = (
  userId,
  checkOut,
  paymentMethod,
  couponCode,
  currentPlatform,
  onSuccess,
  onError
) => {
  const body = resolveBody(
    userId,
    checkOut,
    paymentMethod,
    couponCode
  )

  Transactions.createWithBancolombia(body)
    .then((response) => getUrl(
      1,
      response.id,
      currentPlatform,
      onSuccess,
      onError
    ))
    .catch(() => onError())
}
