import { render, staticRenderFns } from "./SpecialistAppointmentCard.vue?vue&type=template&id=b1f90c4e&scoped=true"
import script from "./SpecialistAppointmentCard.vue?vue&type=script&lang=js"
export * from "./SpecialistAppointmentCard.vue?vue&type=script&lang=js"
import style0 from "./SpecialistAppointmentCard.vue?vue&type=style&index=0&id=b1f90c4e&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b1f90c4e",
  null
  
)

export default component.exports