import { Transactions } from '@seliaco/red-panda'

export const submitWithFullBenefit = (
  checkOutId,
  onSuccess,
  onError
) => {
  Transactions.createWithFullBenefit({
    appointment_id: checkOutId
  })
    .then(() => onSuccess(null))
    .catch(() => onError())
}
