import { Transactions } from '@seliaco/red-panda'

const resolveBody = (
  userId,
  checkOut,
  paymentMethod,
  couponCode = null
) => {
  let body = {
    user_id: userId,
    amount: checkOut.invoice.total,
    coupon_code: couponCode,
    payment_method: {
      id: paymentMethod.id,
      type: paymentMethod.type,
      options: {
        installments: paymentMethod.installments || 1
      },
      payment_description: checkOut.payment_description
    }
  }

  switch (checkOut.type) {
    case 'APPOINTMENT':
      body = {
        ...body,
        appointment: {
          id: checkOut.id,
          specialist: checkOut.appointment.specialist.id
        }
      }
      break
    case 'PACKAGE':
      body = {
        ...body,
        package_id: checkOut.id
      }
      break
    case 'CONFERENCE':
      body = {
        ...body,
        conference_id: checkOut.conferenceData.conference.id,
        quota_id: checkOut.conferenceData.quota.id
      }
      break
  }

  return body
}

export const submitWithCard = (
  userId,
  checkOut,
  paymentMethod,
  couponCode,
  onSuccess,
  onError
) => {
  const body = resolveBody(
    userId,
    checkOut,
    paymentMethod,
    couponCode
  )

  Transactions.createWithCard(body)
    .then((transaction) => onSuccess(transaction.id))
    .catch(() => {
      onError()
    })
}
