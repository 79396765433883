<template>
  <div class="check-out-water-mark">
    <!-- check icon -->
    <div class="check-out-water-mark-check" :style="resolveCheck"></div>

    <!-- wompi text -->
    <div class="check-out-water-mark-text">
      {{ this.$translations.checkout.footer }}
    </div>

    <!-- wompi logo -->
    <div class="check-out-water-mark-logo" :style="resolveWompiLogo"></div>
  </div>
</template>

<script>
import { Wompi, WompiCheck } from '@seliaco/red-panda'

export default {
  name: 'CheckOutWaterMark',
  computed: {
    resolveWompiLogo () {
      return {
        'background-image': `url("${Wompi}")`
      }
    },
    resolveCheck () {
      return {
        'background-image': `url("${WompiCheck}")`
      }
    }
  }
}
</script>

<style lang="sass">
.check-out-water-mark
  display: grid
  grid-template-columns: min-content max-content min-content
  padding: 16px 19px 18px

  &-check,
  &-text,
  &-logo
    align-self: center

  &-check
    margin-right: 11px
    height: 19.7px
    width: 18px
    background-size: contain
    background-repeat: no-repeat
    background-position: center

  &-text
    font-size: var(--sm)
    color: var(--gray-50)

  &-logo
    margin-left: 4px
    height: 14.28px
    width: 42px
    background-size: contain
    background-repeat: no-repeat
    background-position: center

</style>
