<template>
  <div class="review-card">
    <!-- date -->
    <div class="review-card-date margin-bottom-sm">
      <div class="review-card-date-icon icon-recommend-on"></div>
      <div class="review-card-date-text content-title">
        {{ $luxon.DateTime.fromISO(review.created_at).toFormat('LLL dd, yyyy') }}
      </div>
    </div>

    <!-- content -->
    <SectionClamp
      class="review-card-description"
      :max-lines="3"
      :text="review.content"
      :expand-text-values="['read-more', 'read-less']"
      text-color="gray-80"
    />
  </div>
</template>

<script>
import SectionClamp from '@/components/sections/SectionClamp'
export default {
  name: 'ReviewCard',
  components: { SectionClamp },
  props: {
    review: Object
  }
}
</script>

<style lang="sass" scoped>
.review-card
  border-radius: 16px
  padding: 16px
  gap: 16px
  border: 2px solid var(--gray-10)
  background: var(--white)
  box-shadow: var(--shadow-gray)
  min-width: 268px !important
  max-width: 268px !important
  align-self: flex-start
  min-height: 140px
  &-date
    display: flex
    gap: 8px
    &-icon
      flex: none
      color: var(--purple)
      font-size: var(--xl)
      align-self: center
    &-text
      text-transform: capitalize
      flex-grow: 1
      align-self: center
      color: var(--gray-80)
</style>
