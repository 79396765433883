import { Browser } from '@capacitor/browser'
import { Transactions, TransactionsV2 } from '@seliaco/red-panda'

const openUrl = (url, transactionId, platform, onSuccess, onError) => {
  Browser.open({ url }).catch(() => onError())

  if (platform === 'web') {
    return onSuccess(transactionId)
  }

  return Browser.addListener('browserFinished', () => onSuccess(transactionId))
}

export const getUrl = (
  checkOutVersion,
  transactionId,
  platform,
  onSuccess,
  onError
) => {
  let transactionService = TransactionsV2

  if (checkOutVersion !== 2) {
    transactionService = Transactions
  }

  transactionService
    .getById(transactionId)
    .then((response) => {
      let url =
        response?.payment?.data?.payment_method?.extra?.async_payment_url
      const WOMPI_URL =
        response?.payment_response?.data?.payment_method?.extra
          ?.async_payment_url ||
        response?.payment_response?.payment_method?.extra?.async_payment_url ||
        response?.payment_response?.data?.payment_method?.extra?.url

      const COBRE_URL = response?.payment_response?.linkUrl

      if (WOMPI_URL) {
        url = WOMPI_URL
      } else if (COBRE_URL) {
        url = COBRE_URL
      }

      if (url) {
        return openUrl(url, transactionId, platform, onSuccess, onError)
      }

      setTimeout(() => {
        return getUrl(
          checkOutVersion,
          transactionId,
          platform,
          onSuccess,
          onError
        )
      }, 500)
    })
    .catch(() => onError())
}
