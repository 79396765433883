import { submitV2 } from '@/views/check-out/services/index-v2'

import { submitWithPse } from './check-out-with-pse'
import { submitWithCard } from './check-out-with-card'
import { submitWithPackage } from './check-out-with-package'
import { submitWithCoupon } from './check-out-with-coupon'
import { submitWithLoan } from './check-out-with-loan'
import { submitWithFullBenefit } from './check-out-with-full-benefit'
import { submitWithFullDiscount } from './check-out-with-full-discount'
import { submitWithNequi } from '@/views/check-out/services/check-out-with-nequi'
import { submitWithBancolombia } from '@/views/check-out/services/check-out-with-bancolombia'
import { submitWithFullPromotion } from '@/views/check-out/services/check-out-with-full-promotion'

/**
 * @param checkOutVersion
 * @param userId
 * @param checkOut checkout object
 * @param paymentMethod payment method to be used (only for PSE and CARD payments)
 * @param couponCode
 * @param promocodeId
 * @param currentPlatform
 * @param total total to be paid, only as reference since the backend calculates this
 * @param onSuccess function to call on success
 * @param onError function to call on error
 */
export const submit = (
  checkOutVersion,
  userId,
  checkOut,
  paymentMethod,
  couponCode,
  promocodeId,
  currentPlatform,
  total,
  sharedAccountId,
  onSuccess,
  onError
) => {
  if (checkOutVersion === 2) {
    return submitV2(
      checkOutVersion,
      userId,
      checkOut,
      paymentMethod,
      currentPlatform,
      sharedAccountId,
      onSuccess,
      onError
    )
  }

  if (checkOut.type === 'APPOINTMENT' && checkOut.invoice.has_package) {
    return submitWithPackage(userId, checkOut, onSuccess, onError)
  } else if (
    checkOut.type === 'APPOINTMENT' &&
    checkOut.invoice.has_full_benefit
  ) {
    return submitWithFullBenefit(checkOut.id, onSuccess, onError)
  } else if (couponCode && total === 0) {
    return submitWithCoupon(
      userId,
      checkOut,
      couponCode,
      promocodeId,
      onSuccess,
      onError
    )
  } else if (total === 0 && checkOut.invoice.has_promo) {
    return submitWithFullPromotion(
      checkOut.id,
      checkOut.type,
      checkOut.invoice.reference,
      onSuccess,
      onError
    )
  } else if (total === 0) {
    return submitWithFullDiscount(checkOut.id, onSuccess, onError)
  }

  if (!paymentMethod) {
    throw new Error('No payment method was selected')
  }

  switch (paymentMethod.type) {
    case 'PSE':
      return submitWithPse(
        userId,
        checkOut,
        paymentMethod,
        couponCode,
        currentPlatform,
        onSuccess,
        onError
      )
    case 'CARD':
      return submitWithCard(
        userId,
        checkOut,
        paymentMethod,
        couponCode,
        onSuccess,
        onError
      )
    case 'NEQUI':
      return submitWithNequi(
        userId,
        checkOut,
        paymentMethod,
        couponCode,
        onSuccess,
        onError
      )
    case 'BANCOLOMBIA_TRANSFER':
      return submitWithBancolombia(
        userId,
        checkOut,
        paymentMethod,
        couponCode,
        currentPlatform,
        onSuccess,
        onError
      )
    case 'ADDI':
      return submitWithLoan(
        userId,
        checkOut,
        paymentMethod,
        couponCode,
        currentPlatform,
        onSuccess,
        onError
      )
  }
}
