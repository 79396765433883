import { CheckOutV2, CheckOutSharedAccount } from '@seliaco/red-panda'
import { getUrl } from '@/views/check-out/services/payment-gateway-helper'
import { Browser } from '@capacitor/browser'

/**
 * @param checkOutVersion
 * @param userId
 * @param checkOut checkout object
 * @param paymentMethod payment method to be used (only for PSE and CARD payments)
 * @param currentPlatform
 * @param onSuccess function to call on success
 * @param onError function to call on error
 */
export const submitV2 = (
  checkOutVersion,
  userId,
  checkOut,
  paymentMethod,
  currentPlatform,
  sharedAccountId,
  origin,
  onSuccess,
  onError
) => {
  let callBack = (response) => {
    onSuccess(response.id)
  }

  if (paymentMethod && paymentMethod.type) {
    switch (paymentMethod.type) {
      case 'PSE':
      case 'BANCOLOMBIA_TRANSFER':
      case 'COBRE':
      case 'BANCOLOMBIA_BNPL':
        callBack = (response) =>
          getUrl(
            checkOutVersion,
            response.id,
            currentPlatform,
            onSuccess,
            onError
          )
        break
      case 'ADDI':
        callBack = (response) => {
          const redirect =
            response?.redirect || response?.payment_response?.redirect || null

          Browser.open({ url: redirect })
            .then(() => {
              if (currentPlatform === 'web') {
                onSuccess(response.id)
              } else {
                Browser.addListener('browserFinished', () =>
                  onSuccess(response.id)
                )
              }
            })
            .catch(() => onError())
        }
        break
    }
  }

  let body = {
    type: paymentMethod?.type,
    user_type: paymentMethod?.user_type || 'PERSON',
    payment_description: checkOut.payment_description,
    /**
     * Card
     */
    id: paymentMethod?.id,
    installments: paymentMethod?.installments || 1,
    /**
     * Nequi
     */
    phone_number: paymentMethod?.phone,
    /**
     * PSE
     */
    user_legal_id_type: paymentMethod?.user_legal_id_type || 'CC',
    user_legal_id:
      paymentMethod?.user_legal_id || paymentMethod?.identification_number,
    financial_institution_code: paymentMethod?.financial_institution,
    /**
     * ADDI
     */
    place_id: paymentMethod?.place_id,

    /**
     * BANCOLOMBIA_BNPL
     */
    name: paymentMethod?.name,
    last_name: paymentMethod?.last_name,
    phone_code: paymentMethod?.dialCode
  }

  if (paymentMethod?.type === 'COBRE') {
    body = {
      /**
       * COBRE
       */
      type: paymentMethod?.type,
      document: paymentMethod?.document,
      document_type: paymentMethod?.document_type
    }
  }

  if (sharedAccountId && origin === 'shared-account') {
    CheckOutSharedAccount.pay(body, sharedAccountId)
      .then((response) => callBack(response))
      .catch((e) => onError(e))
    return
  }

  CheckOutV2.pay(body)
    .then((response) => callBack(response))
    .catch((e) => onError(e))
}
