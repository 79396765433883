<template>
  <div>
    <!-- name -->
    <div class="form-field">
      <div class="margin-top-xs">
        <Checkbox
          :text="$translations.form['use-phone']"
          type="fullcheck"
          :checked="validPhone"
          @value="changePhone"
        />
      </div>
    </div>
    <!-- phone -->
    <PhoneField
      class="margin-top"
      :v-model="phone"
      :label="$translations.form['phone-addi']"
      :value="{ dialCode: form.dialCode, phone: form.phone, country: 'CO' }"
      :disabled-country="true"
      @change-phone="changePhoneField"
    />

    <!-- address -->
    <InputGoogleMap
      :placeholder="$translations.form['address']"
      @optionSelected="setPlaceId"
      v-if="!isTestMode"
    />
    <InputTextField
      class="margin-top"
      :label="$translations.form['address']"
      v-model="form.place_id"
      v-if="isTestMode"
    />

    <!-- document & number -->
    <div class="form-field-group">
      <!-- document -->
      <DropdownField
        class="margin-top"
        :options="options.documentType"
        :v-model="form.identification_type"
        :value="form.identification_type"
        :label="$translations.form['document-type']"
        :mobile-options="true"
        :disabled="true"
      />

      <!-- number -->
      <InputMaskField
        class="margin-top"
        mask="##############"
        :label="$translations.form['document-number']"
        v-model="form.identification_number"
      />
    </div>
    <InfoBanner
      icon="icon-warning-circle-off "
      :text="$translations['info-banner'].addi"
    />
  </div>
</template>

<script>
import { Checkbox, InputGoogleMap, InfoBanner } from '@seliaco/red-panda'
import {
  InputTextField,
  InputMaskField,
  DropdownField,
  PhoneField
} from '@seliaco/sea-otter'

export default {
  name: 'LoanForm',
  components: {
    DropdownField,
    PhoneField,
    Checkbox,
    InputGoogleMap,
    InfoBanner,
    InputTextField,
    InputMaskField
  },
  data () {
    return {
      form: {
        user_id: '',
        dialCode: '57',
        phone: '',
        place_id: null,
        identification_type: 'CC',
        identification_type_name: '',
        identification_number: null,
        country: 'CO'
      },
      phone: {
        phone: '',
        country: 'CO',
        dialCode: '57'
      },
      validPhone: true,
      options: {
        documentType: [
          {
            label: 'Cédula Ciudadanía',
            value: 'CC'
          }
        ]
      }
    }
  },
  mounted () {
    this.setForm()
  },
  methods: {
    setForm () {
      const user = this.$store.getters['auth/user']
      const dialCode = user.dial_code.replace('+', '')

      this.form = {
        ...this.form,
        user_id: user.id,
        type: 'PACKAGE',
        phone: user.phone,
        dialCode,
        country: user.country,
        identification_type: 'CC',
        identification_type_name: 'Cédula Ciudadanía'
      }
    },
    changePhoneField ({ dialCode, phone }) {
      this.form = {
        ...this.form,
        dialCode,
        phone
      }
    },
    changePhone (val) {
      const user = this.$store.getters['auth/user']
      if (val.isChecked) {
        this.form.phone = `${user.phone}`
        return
      }
      this.form.phone = ''
    },
    setPlaceId (val) {
      this.form.place_id = val?.value ? val.value : ''
    }
  },
  computed: {
    isTestMode () {
      return process.env.VUE_APP_MODE === 'DEV'
    }
  },
  watch: {
    form: {
      handler () {
        const valid = Boolean(
          this.form.user_id &&
            this.form.phone &&
            this.form.dialCode &&
            this.form.identification_type &&
            this.form.identification_number &&
            this.form.place_id
        )

        if (valid) {
          this.$emit('form', this.form)
        } else {
          this.$emit('form', null)
        }
      },
      deep: true
    }
  }
}
</script>
<style lang="sass" scoped>
.form-field > input
  border-radius: 8px !important

::v-deep .input-autocomplete
  margin-top: 16px
  input
    height: 52px !important
    border-radius: 8px !important
</style>
