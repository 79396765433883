<template>
  <div class="checkout-invoice" v-if="invoice && currency">
    <!-- details -->
    <template v-if="loading">
      <div class="checkout-invoice-detail">
        <div class="checkout-invoice-detail-label">
          <Skeleton width="100%" height="21px" border-radius="16px" />
        </div>

        <div class="checkout-invoice-detail-value">
          <Skeleton width="100px" height="21px" border-radius="16px" />
        </div>
      </div>
    </template>

    <template v-else>
      <div class="checkout-invoice-detail" v-if="invoice && invoice.has_package">
        <div class="checkout-invoice-detail-label">
          {{ $translations.checkout['package-method'] }}
        </div>

        <div class="checkout-invoice-detail-value">
          1
        </div>
      </div>

      <template v-else>
        <div class="checkout-invoice-detail" v-for="(detail, key) in invoice.details" :key="key">
          <template v-if="showInvoiceLine(detail)">
            <div class="checkout-invoice-detail-label">
              {{ detail.name }}
            </div>

            <div class="checkout-invoice-detail-value">
              {{ detail.noDeductible ? '' : '-' }} {{ (detail.value / 100) | CurrencyFilter(currency.toUpperCase()) }}
            </div>
          </template>
        </div>
      </template>
    </template>

    <!-- total -->
    <div class="checkout-invoice-total">
      <div class="checkout-invoice-total-label">
        {{ $translations.checkout.total }}
      </div>

      <Skeleton width="130px" height="45px" border-radius="16px" v-if="loading" />
      <div class="checkout-invoice-total-value" v-else>
        <template v-if="invoice.has_package">
          1 {{ $translations.checkout['package-method'] }}
        </template>

        <template v-else>
          {{ (invoice.total / 100) | CurrencyFilter(currency.toUpperCase()) }}
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { Skeleton, CurrencyFilter } from '@seliaco/red-panda'

export default {
  name: 'CheckOutInvoice',
  props: {
    /**
     * {
     *   total: Number,
     *   details: {
     *     name: String,
     *     value: Number,
     *     noDeductible: Boolean
     *   }[],
     *   has_package: Boolean
     * }
     */
    invoice: Object,
    loading: Boolean,
    currency: String
  },
  filters: {
    CurrencyFilter
  },
  computed: {
    showInvoiceLine () {
      return (data) => {
        return !data?.meta?.rewards?.find(o => o.type === 'CASHBACK' || o.type === 'CREATE_PROMOCODE')
      }
    }
  },
  components: {
    Skeleton
  }
}
</script>

<style lang="sass" scoped>
.checkout-invoice
  margin-top: 16px

  &-detail,
  &-total
    display: grid
    grid-template-columns: 1fr max-content
    gap: 16px

  &-detail
    margin-bottom: 8px

    &-label,
    &-value
      align-self: center
      color: var(--gray-60)
      font-size: var(--tiny)

  &-total
    margin-top: 16px
    padding-top: 16px
    border-top: 1px solid var(--gray-20)
    align-content: center

    &-label,
    &-value
      font-weight: 600
      align-self: center
      color: var(--gray-80)
      font-size: var(--tiny)

    &-value
      padding: 12px 16px
      border-radius: 16px
      background: var(--gray-10)

</style>
