<template>
  <div>
    <FullScreenDialog
      v-if="currentModal"
      :title="modal[currentModal].title"
      :subtitle="loadSubtitle"
      :illustration="modal[currentModal].illustration"
      :show-icon-loader="modal[currentModal].loading"
      :background-style="modal[currentModal].backgroundStyle"
      :canBeClosed="canCloseModal"
      :backdrop-closes="canCloseBackdrop"
      :icon="modal[currentModal].icon"
      :iconColor="modal[currentModal].iconColor"
      @close="close"
    >
      <template v-slot:body>
        <template v-if="currentModal === 'ERROR'">
          <div class="space-y-2">
            <Button
              :text="$translations.checkout.modals.error.confirm"
              @clicked="handleActionEvent('go-checkout')"
            />
          </div>
        </template>

        <template v-if="currentModal === 'ERROR_EXPIRED'">
          <div class="space-y-2">
            <Button
              :text="$translations.checkout.modals['error-expired'].confirm"
              @clicked="handleActionEvent('create-appointment')"
            />
          </div>
        </template>

        <template
          v-if="
            currentModal === 'SUCCESS_APPOINTMENT' ||
            currentModal === 'SUCCESS_PACKAGE' ||
            currentModal === 'SUCCESS_CONFERENCE'
          "
        >
          <div class="space-y-2">
            <div class="margin-bottom">
              <SpecialistAppointmentCard
                v-if="specialist && currentModal === 'SUCCESS_PACKAGE'"
                :picture="specialist.picture"
                :title="specialist.name"
                :subtitle="specialist.type.name"
                :description="specialist.address"
              />

              <AppointmentCard
                v-selectable-container="{
                  callback: navigateSpecialistProfile,
                  elementLevel: 1
                }"
                v-if="currentModal === 'SUCCESS_APPOINTMENT'"
                :image="checkOut.appointment.specialist.picture"
                :title="checkOut.appointment.specialist.name"
                :middle-line="specialistCardDate"
                :bottom-line="specialistCardService"
                :service-icon="checkOut.appointment.service.icon_name"
                :arrow-action="{ callback: () => true }"
              />
            </div>

            <!-- APPOINTMENT -->
            <template
              v-if="
                currentModal === 'SUCCESS_APPOINTMENT' ||
                currentModal === 'SUCCESS_CONFERENCE'
              "
            >
              <Button
                :text="
                  $translations.checkout.modals['success-appointment'].primary
                "
                @clicked="handleActionEvent('recommendations')"
              />

              <Button
                :text="
                  $translations.checkout.modals['success-appointment'].secondary
                "
                type="outline"
                @clicked="handleActionEvent('go-home')"
              />
            </template>
            <!-- PACKAGE -->
            <template v-if="currentModal === 'SUCCESS_PACKAGE'">
              <Button
                v-if="specialist"
                class="margin-bottom"
                :text="$translations.checkout.modals['success-package'].primary"
                @clicked="handleActionEvent('create-appointment')"
              />
              <Button
                v-if="specialist"
                :text="
                  $translations.checkout.modals['success-package'].secondary
                "
                type="outline"
                @clicked="handleActionEvent('go-home')"
              />
              <Button
                v-if="!specialist"
                :text="
                  $translations.checkout.modals['success-generic-package']
                    .primary
                "
                @clicked="handleActionEvent('available-specialists')"
              />
              <Button
                v-if="!specialist"
                :text="
                  $translations.checkout.modals['success-generic-package']
                    .secondary
                "
                type="outline"
                @clicked="handleActionEvent('go-home')"
              />
            </template>
          </div>
        </template>

        <template v-if="currentModal === 'SUCCESS_PROGRAM'">
          <div class="space-y-2">
            <Button
              class="margin-bottom"
              :text="$translations.checkout.modals['success-program'].primary"
              @clicked="handleActionEvent('choose-specialist')"
            />
            <Button
              :text="$translations.checkout.modals['success-program'].secondary"
              type="outline"
              @clicked="handleActionEvent('go-home')"
            />
          </div>
        </template>

        <template
          v-if="
            currentModal === 'SUCCESS_APPOINTMENT_NUTRITION' ||
            currentModal === 'SUCCESS_PACKAGE_NUTRITION'
          "
        >
          <div class="space-y-2">
            <!-- APPOINTMENT -->
            <template v-if="currentModal === 'SUCCESS_APPOINTMENT_NUTRITION'">
              <Button
                :text="
                  $translations.checkout.modals['success-appointment-nutrition']
                    .primary
                "
                @clicked="handleActionEvent('go-nutrition-form')"
              />

              <Button
                :text="
                  $translations.checkout.modals['success-appointment-nutrition']
                    .secondary
                "
                type="outline"
                @clicked="handleActionEvent('recommendations')"
              />

              <Button
                :text="
                  $translations.checkout.modals['success-appointment-nutrition']
                    .third
                "
                type="outline"
                @clicked="handleActionEvent('go-home')"
              />
            </template>

            <!-- PACKAGE -->
            <!--TODO: Package in nutrition service-->
            <template v-if="currentModal === 'SUCCESS_PACKAGE_NUTRITION'">
              <Button
                :text="
                  $translations.checkout.modals['success-appointment-nutrition']
                    .primary
                "
                @clicked="handleActionEvent('go-nutrition-form')"
              />

              <Button
                :text="
                  $translations.checkout.modals['success-appointment-nutrition']
                    .secondary
                "
                type="outline"
                @clicked="handleActionEvent('go-home')"
              />
            </template>
          </div>
        </template>

        <!-- cancel appointment modal v2 -->
        <template v-if="currentModal === 'CANCEL_APPOINTMENT'">
          <div style="display: flex; flex-direction: column; gap: 8px">
            <Button
              :text="$translations.checkout.modals.cancel.confirm"
              :loading="loading"
              :disable="editLoading"
              color="red"
              @clicked="cancelAppointmentV2"
            />

            <Button
              :text="$translations.checkout.modals.cancel.return"
              :disable="loading || editLoading"
              type="outline"
              color="gray"
              @clicked="handleActionEvent('go-checkout')"
            />

            <Button
              :text="$translations.checkout.modals.cancel.edit"
              :disable="loading"
              :loading="editLoading"
              style="color: var(--red)"
              type="link"
              color="red"
              @clicked="editAppointment"
            />
          </div>
        </template>

        <template v-if="currentModal === 'CANCEL_CONFERENCE'">
          <div class="space-y-2">
            <Button
              :text="$translations.checkout.modals['cancel-conference'].confirm"
              :loading="loading"
              color="red"
              @clicked="cancelConference"
            />

            <Button
              :text="$translations.checkout.modals['cancel-conference'].cancel"
              :disable="loading"
              type="outline"
              color="gray"
              @clicked="handleActionEvent('go-checkout')"
            />
          </div>
        </template>

        <template v-if="currentModal === 'TIMEOUT_CONFERENCE'">
          <div class="space-y-2">
            <Button
              :text="
                $translations.checkout.modals['timeout-conference'].confirm
              "
              :loading="loading"
              color="red"
              @clicked="$emit('reset')"
            />

            <Button
              :text="$translations.general.close"
              :disable="loading"
              type="outline"
              color="gray"
              @clicked="cancelConference"
            />
          </div>
        </template>
      </template>
    </FullScreenDialog>
  </div>
</template>

<script>
import {
  FullScreenDialog,
  IlustrationName,
  Button,
  Appointments,
  AppointmentCard,
  Conferences,
  selectableContainer,
  Settings
} from '@seliaco/red-panda'
import SpecialistAppointmentCard from '@/components/cards/SpecialistAppointmentCard'
import { VideoCallRecommendations } from '@/constants/videocall-recommendations'
import { PaymentEvent } from '@/types/events'

import { Browser } from '@capacitor/browser'
import { mapGetters } from 'vuex'

export default {
  name: 'CheckOutModals',
  props: {
    /**
     * One of:
     * - LOADING
     * - ERROR
     * - CANCEL_APPOINTMENT
     * - SUCCESS_APPOINTMENT
     * - SUCCESS_PACKAGE
     * - SUCCESS_APPOINTMENT_NUTRITION
     * - SUCCESS_PACKAGE_NUTRITION
     * - SUCCESS_PROGRAM
     * - CANCEL_CONFERENCE
     * - SUCCESS_CONFERENCE
     * - TIMEOUT_CONFERENCE
     */
    currentModal: String,
    id: String,
    specialistId: String,
    serviceType: String,
    specialist: {
      picture: String,
      name: String,
      speciality: String,
      address: String
    },
    checkOut: Object
  },
  components: {
    FullScreenDialog,
    AppointmentCard,
    SpecialistAppointmentCard,
    Button
  },
  directives: { selectableContainer },
  data () {
    const translate = this.$translations
    return {
      modal: {
        LOADING: {
          title: translate.checkout.modals.loading.title,
          subtitle: translate.checkout.modals.loading.description,
          loading: true,
          backgroundStyle: 'white'
        },
        LOADING_NEQUI: {
          title: translate.checkout.modals['loading-nequi'].title,
          subtitle: translate.checkout.modals['loading-nequi'].description,
          loading: true,
          backgroundStyle: 'white'
        },
        LOADING_ADDI_RESULT: {
          title: translate.checkout.modals['loading-addi-result'].title,
          subtitle:
            translate.checkout.modals['loading-addi-result'].description,
          loading: true,
          backgroundStyle: 'white'
        },
        LOADING_ADDI_URL: {
          title: translate.checkout.modals['loading-addi-url'].title,
          subtitle: null,
          loading: true,
          backgroundStyle: 'white'
        },
        ERROR: {
          title: translate.checkout.modals.error.title,
          subtitle: translate.checkout.modals.error.description,
          illustration: IlustrationName.failed,
          backgroundStyle: 'white'
        },
        ERROR_EXPIRED: {
          title: translate.checkout.modals['error-expired'].title,
          subtitle: translate.checkout.modals['error-expired'].description,
          illustration: IlustrationName.failed,
          backgroundStyle: 'white'
        },
        CANCEL_APPOINTMENT: {
          title: translate.checkout.modals.cancel.title,
          subtitle: translate.checkout.modals.cancel.description,
          icon: 'icon-close-circle-on',
          iconColor: 'red',
          backgroundStyle: 'transparent'
        },
        SUCCESS_APPOINTMENT: {
          title: translate.checkout.modals['success-appointment'].title,
          subtitle:
            translate.checkout.modals['success-appointment'].description,
          illustration: IlustrationName.success,
          backgroundStyle: 'gradient'
        },
        SUCCESS_APPOINTMENT_NUTRITION: {
          title:
            translate.checkout.modals['success-appointment-nutrition'].title,
          subtitle:
            translate.checkout.modals['success-appointment-nutrition']
              .description,
          illustration: IlustrationName.success,
          backgroundStyle: 'gradient'
        },
        SUCCESS_PACKAGE_NUTRITION: {
          title:
            translate.checkout.modals['success-appointment-nutrition'].title,
          subtitle:
            translate.checkout.modals['success-appointment-nutrition']
              .description,
          illustration: IlustrationName.success,
          backgroundStyle: 'gradient'
        },
        SUCCESS_CONFERENCE: {
          title: translate.checkout.modals['success-conference'].title,
          subtitle: translate.checkout.modals['success-conference'].description,
          illustration: IlustrationName.success,
          backgroundStyle: 'gradient'
        },
        SUCCESS_PACKAGE: {
          title: this.specialist
            ? translate.checkout.modals['success-package'].title
            : translate.checkout.modals['success-generic-package'].title,
          subtitle: [
            translate.checkout.modals['success-package'].description,
            translate.checkout.modals['success-generic-package'].description
          ],
          illustration: IlustrationName.success,
          backgroundStyle: 'gradient'
        },
        SUCCESS_PROGRAM: {
          title: translate.checkout.modals['success-program'].title,
          subtitle: translate.checkout.modals['success-program'].description,
          illustration: IlustrationName.success,
          backgroundStyle: 'gradient'
        },
        CANCEL_CONFERENCE: {
          title: translate.checkout.modals['cancel-conference'].title,
          subtitle: translate.checkout.modals['cancel-conference'].description,
          icon: 'icon-close-circle-on',
          iconColor: 'red',
          backgroundStyle: 'transparent'
        },
        TIMEOUT_CONFERENCE: {
          title: translate.checkout.modals['timeout-conference'].title,
          subtitle: translate.checkout.modals['timeout-conference'].description,
          icon: 'icon-timer-on',
          iconColor: 'red',
          backgroundStyle: 'transparent'
        }
      },
      loading: false,
      editLoading: false,
      settingUrl: null,
      recommendationsUrl: VideoCallRecommendations
    }
  },
  async mounted () {
    if (this.currentModal === 'SUCCESS_APPOINTMENT') {
      PaymentEvent.paymentConfirmationViewed({
        user: this.user
      })
    }

    this.settingUrl = await Settings.get('URL_NUTRITION_TYPEFORM').then(
      (response) => response.parse_value
    )
  },
  methods: {
    handleActionEvent (actionName) {
      const lang = this.$store.getters.language.lang
      switch (actionName) {
        case 'go-checkout':
          this.$emit('currentModalChanged', null)
          break

        case 'go-home':
          this.$router.push({ name: 'Home' })
          break

        case 'recommendations':
          Browser.open({
            url: this.recommendationsUrl[lang]
          })
          break

        case 'create-appointment':
          this.$router.push({
            name: 'Schedule',
            query: {
              specialistId: this.specialistId,
              back: '/'
            }
          })
          break

        case 'available-specialists':
          this.$router.push({
            name: 'PackageSpecialists',
            params: {
              packageId: this.id
            }
          })
          break

        case 'go-nutrition-form':
          window.open(this.settingUrl, 'blank')
          break

        case 'choose-specialist':
          this.$emit('view-specialists')
      }
    },
    cancelConference () {
      this.loading = true
      Conferences.deletePlaceAtConference(this.$route.query.quotaId)
        .then(() => {
          this.$router.push({
            name: 'Home'
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    cancelAppointmentV2 () {
      this.$globalEvent.$emit('modal/cancel', {
        showDialog: {
          cancel: {
            open: true,
            close: false
          }
        },
        appointment: {
          id: this.id,
          serviceType: this.serviceType,
          redirect: 'Home'
        }
      })
    },
    editAppointment () {
      this.editLoading = true
      Appointments.cancelAppointment(this.id)
        .then(() => {
          this.$router.push({
            name: 'Schedule',
            query: {
              specialistId: this.specialistId,
              back: this.$route.fullPath
            }
          })
        })
        .catch((error) => {
          this.$toast({
            text: error.message,
            severity: 'error'
          })
        })
        .finally(() => {
          this.editLoading = false
        })
    },
    close () {
      const actionName =
        this.currentModal === 'CANCEL_APPOINTMENT' ||
        this.currentModal === 'ERROR' ||
        this.currentModal === 'CANCEL_CONFERENCE'
          ? 'go-checkout'
          : 'go-home'

      this.handleActionEvent(actionName)
    },
    navigateSpecialistProfile () {
      this.$router.push({
        name: 'AppointmentDetails',
        params: {
          id: this.checkOut.id
        }
      })
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user'
    }),

    loadSubtitle () {
      if (this.currentModal === 'SUCCESS_PACKAGE') {
        return this.modal[this.currentModal].subtitle[this.specialist ? 0 : 1]
      } else {
        return this.modal[this.currentModal].subtitle
      }
    },
    canCloseModal () {
      if (this.modal[this.currentModal].backgroundStyle === 'transparent') {
        return false
      }

      if (this.loading) {
        return false
      }

      return this.currentModal !== 'LOADING'
    },
    canCloseBackdrop () {
      return this.currentModal.includes('CONFERENCE')
    },
    specialistCardDate () {
      const date = this.$moment(this.checkOut.appointment.date)
        .tz(this.$store.getters.userTimezone)
        .format('DD MMMM, ddd • h:mm a')
      const duration = `${this.checkOut.appointment.service.duration} (${this.$translations.time['minutes-sort']})`

      return `${date} ${duration}`
    },
    specialistCardService () {
      const modality =
        this.$translations.appointments.virtual[
          this.checkOut.appointment.virtual.toString()
        ]

      return `${modality} • ${this.checkOut.appointment.service.name}`
    }
  }
}
</script>
