import { Transactions } from '@seliaco/red-panda'

const resolveBody = (
  userId,
  checkOut,
  code,
  promocodeId
) => {
  return {
    user_id: userId,
    appointment_id: checkOut.id,
    code,
    promocode_id: promocodeId
  }
}

export const submitWithCoupon = (
  userId,
  checkOut,
  couponCode,
  promocodeId,
  onSuccess,
  onError
) => {
  const body = resolveBody(
    userId,
    checkOut,
    couponCode,
    promocodeId
  )

  Transactions.createWithCoupon(body)
    .then(() => onSuccess(null))
    .catch(() => onError())
}
