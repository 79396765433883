<template>
  <div class="segment padding-all-zero" v-if="showWarrentyBanner">
    <div class="segment-content">
      <div class="checkout-banner">
        <div
          class="checkout-banner-text body"
          v-html="$translations.checkout['banner-warranty']"
        />
        <img class="checkout-banner-img" :src="img" alt="checkout banner" />
      </div>
    </div>
  </div>
</template>

<script>
import { PNG } from '@seliaco/red-panda'
import { mapGetters } from 'vuex'

export default {
  name: 'CheckOutBanner',
  data () {
    return {
      img: PNG.GiftSecondChance
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      warrentyBanner: 'growthBook/warrentyBanner'
    }),
    showWarrentyBanner () {
      return this.user.status === 'ACCOUNT_CREATED' && this.warrentyBanner
    }
  }
}
</script>

<style lang="sass" scoped>
.checkout-banner
  padding: 16px
  background: var(--blue-10)
  position: relative
  border-radius: 0px
  &-text
    color: var(--blue-120)
    width: 80%
  &-img
    width: 81px
    height: 81px
    position: absolute
    right: 0
    bottom: -15px

@media (min-width: 480px)
  .checkout-banner
    border-radius: 16px
</style>
