<template>
  <div>
    <!-- name -->
    <DropdownField
      class="margin-top"
      :options="options.bankAccounts"
      :label="$translations.form['bank-name']"
      v-model="form.financial_institution"
      :mobile-options="true"
    />

    <!-- person type -->
    <DropdownField
      class="margin-top"
      :options="options.personType"
      :label="$translations.form['person-type']"
      v-model="form.user_type"
      :mobile-options="true"
    />

    <!-- document & number -->
    <div class="form-group">
      <!-- document -->
      <DropdownField
        :options="options.documentType"
        :label="$translations.form['document-type']"
        v-model="form.user_legal_id_type"
        :mobile-options="true"
      />

      <!-- number -->
      <InputMaskField
        mask="##############"
        :label="$translations.form['document-number']"
        v-model="form.user_legal_id"
      />
    </div>
  </div>
</template>

<script>
import { Banks } from '@seliaco/red-panda'
import { InputMaskField, DropdownField } from '@seliaco/sea-otter'

export default {
  name: 'PseForm',
  components: {
    DropdownField,
    InputMaskField
  },
  data () {
    return {
      loading: false,
      form: {
        type: 'PSE',
        user_type: null,
        user_legal_id_type: null,
        user_legal_id: null,
        financial_institution: null,
        payment_description: null
      },
      options: {
        personType: [
          {
            value: '0',
            label: 'Natural'
          },
          {
            value: '1',
            label: 'Jurídica'
          }
        ],
        documentType: [
          {
            label: 'Cédula Ciudadanía',
            value: 'CC'
          },
          {
            label: 'Cédula Extranjería',
            value: 'CE'
          },
          {
            label: 'NIT',
            value: 'NIT'
          }
        ],
        bankAccounts: []
      }
    }
  },
  mounted () {
    this.getBanks()
  },
  methods: {
    getBanks () {
      this.loading = true

      Banks.pse()
        .then((response) => {
          const data = response.data

          this.options.bankAccounts = data.map((option) => {
            return {
              label: option.financial_institution_name,
              value: option.financial_institution_code
            }
          })
        })
        .catch(() => {
          this.$toast({
            text: this.$translations.error['bank-list'],
            severity: 'error'
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    validForm () {
      return Boolean(
        this.form.financial_institution &&
          this.form.user_type &&
          this.form.user_legal_id_type &&
          this.form.user_legal_id
      )
    }
  },
  watch: {
    form: {
      handler () {
        const valid = this.validForm()
        if (valid) {
          this.$emit('form', this.form)
        } else {
          this.$emit('form', null)
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="sass" scoped>
.form-group
  display: flex
  gap: 8px
  margin-top: 16px
</style>
