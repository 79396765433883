<template>
  <div>
    <PageContainer
      :title="$translations.checkout.title"
      :action="actions.close"
      :submit="actions.submit"
    >
      <template v-slot:content>
        <!-- subtitle -->
        <div
          class="segment subtitle-segment"
          v-if="loading.checkOut || (checkOut && !checkOut.invoice.has_package)"
        >
          <div class="segment-content">
            <Skeleton
              v-if="loading.checkOut"
              width="100%"
              height="72px"
              border-radius="16px"
            />
            <div class="body" v-else v-html="detailText"></div>
          </div>
        </div>

        <!-- payment data (appointment, conference or package) -->
        <div class="page-segment margin-top">
          <div class="page-segment-content">
            <div @click="toggleDetail">
              <Heading
                :text="texts[type].detail"
                :action="actions.toggleDetail"
              />
            </div>

            <div class="margin-top" v-if="!collapsed.detail">
              <template v-if="type === 'APPOINTMENT'">
                <AppointmentCard
                  :loading="loading.checkOut"
                  :image="specialistCardImage"
                  :title="specialistCardTitle"
                  :middle-line="specialistCardDate"
                  :bottom-line="specialistCardService"
                  :service-icon="specialistCardIcon"
                />
              </template>

              <template v-if="type === 'CONFERENCE'">
                <Skeleton
                  v-if="loading.checkOut"
                  width="100%"
                  height="90px"
                  border-radius="16px"
                />

                <ConferenceAppointmentCard
                  v-else
                  :conference="checkOut.conferenceData.conference"
                  :active="false"
                  :no-clickable="false"
                />
              </template>

              <template v-if="type === 'PACKAGE'">
                <Skeleton
                  v-if="loading.checkOut"
                  width="100%"
                  height="148px"
                  border-radius="16px"
                />

                <PackageCard
                  v-else
                  :serviceName="packageCardData.product_packages[0].name"
                  :packageData="packageCardData"
                  :currency="checkOut.currency"
                  :show-button="false"
                  :icon-name="packageCardData.icon_name"
                >
                </PackageCard>
              </template>

              <template v-if="type === 'PROGRAM'">
                <Skeleton
                  v-if="loading.checkOut"
                  width="100%"
                  height="90px"
                  border-radius="16px"
                />

                <ProgramAppointmentCard v-else :program="checkOut.package" />
              </template>
            </div>
          </div>
        </div>

        <!-- business benefit -->
        <div
          class="page-segment"
          v-if="checkOut && checkOut.benefit && !checkOut.invoice.has_package"
        >
          <div class="page-segment-content">
            <CheckOutBenefit :benefit="checkOut.benefit" />
          </div>
        </div>

        <!-- coupon -->
        <div class="page-segment" v-if="showCouponSection">
          <div class="page-segment-content">
            <CheckOutCoupon
              :id="id"
              :type="type"
              :initial-value="coupon"
              :loading-checkout="loading.checkOut"
              :check-out-version="checkOutVersion"
              @invoice="handleInvoiceChange"
              @clear="handleInvoiceChange"
            />
          </div>
        </div>

        <!-- packages (ONLY for appointments) -->
        <div
          class="page-segment"
          v-if="type !== 'PACKAGE' && checkOut && checkOut.invoice.has_package"
        >
          <div class="page-segment-content">
            <CheckOutPackage :package="checkOut.userPackage" />
          </div>
        </div>
        <!-- payment with credits -->
        <div class="page-segment" v-if="showCredits">
          <div class="page-segment-content">
            <Heading
              class="margin-y"
              :text="$translations.checkout.credits.title"
            />

            <div class="balance-available margin-y">
              <div
                class="body"
                v-text="$translations.checkout.credits.available"
              />
              <Checkbox
                :text="creditsUse"
                type="fullcheck"
                @value="toggleCredits"
                class="balance-checkbox"
              />
            </div>

            <div class="balance-description margin-bottom">
              <div class="body-small text-gray-50" v-html="totalBalance" />
            </div>
          </div>
        </div>

        <!-- payment summary -->
        <div class="page-segment" v-if="role !== 'USER_HIDE_PRICES'">
          <div class="page-segment-content">
            <Heading :text="$translations.checkout.summary" />

            <CheckOutInvoice
              :invoice="invoice"
              :currency="currency"
              :loading="loading.checkOut"
            />
          </div>
        </div>

        <!-- payment methods -->
        <div class="page-segment" v-if="showPaymentMethods">
          <div class="page-segment-content">
            <Heading :text="$translations.checkout['payment-method']" />

            <CheckOutPaymentMethod
              :payment-methods="paymentMethods"
              :loading="loading.checkOut"
              :currency="currency"
              @go-add-card="handleGoToAddCard"
              @payment-method="handleSelectPaymentMethod"
            />
          </div>
        </div>

        <!-- Warrenty Banner -->
        <CheckOutBanner />

        <!-- Reviews Carrousel -->
        <CheckoutReviews />

        <!-- Wompi footer -->
        <CheckOutWaterMark
          v-show="showWompiCheck && currency === 'COP'"
          id="waterMark"
        />
      </template>
    </PageContainer>

    <!-- modals -->
    <CheckOutModals
      :current-modal="currentModal"
      :id="id"
      :specialist="checkOutModalSpecialist"
      :service-type="serviceType"
      :specialist-id="specialistId"
      :check-out="checkOut"
      @currentModalChanged="handleCurrentModalChanges"
      @view-specialists="navigateToSpecialistProfile"
      @reset="resetTimer"
    />
  </div>
</template>

<script>
import {
  CheckOut,
  CheckOutV2,
  PageContainer,
  Skeleton,
  Heading,
  AppointmentCard,
  Transactions,
  TransactionsV2,
  Conferences,
  CurrencyFilter,
  Checkbox,
  Appointments,
  CheckOutSharedAccount
} from '@seliaco/red-panda'
import CheckOutModals from '@/views/check-out/components/CheckOutModals'
import PackageCard from '@/views/promos/packages/components/PackageCard'
import ConferenceAppointmentCard from '@/components/appointment/ConferenceCard'
import ProgramAppointmentCard from '@/components/appointment/ProgramCard'
import CheckOutInvoice from '@/views/check-out/components/CheckOutInvoice'
import CheckOutCoupon from '@/views/check-out/components/CheckOutCoupon'
import CheckOutPackage from '@/views/check-out/components/CheckOutPackage'
import CheckOutPaymentMethod from '@/views/check-out/components/CheckOutPaymentMethod'
import CheckOutWaterMark from '@/views/check-out/components/CheckOutWaterMark'
import CheckOutBenefit from '@/views/check-out/components/CheckOutBenefit'
import CheckOutBanner from '@/views/check-out/components/CheckOutBanner'
import { submit } from './services'
import { PaymentEvent, SpecialistCard } from '@/types/events'
import { mapGetters } from 'vuex'
import CheckoutReviews from '@/views/check-out/components/CheckOutReviews'
import { loadStripe } from '@stripe/stripe-js'

export default {
  name: 'CheckOut',
  props: {
    /**
     * 'APPOINTMENT' | 'PACKAGE' | 'CONFERENCE' | 'PROGRAM'
     */
    type: String,
    id: String,
    serviceType: String,
    /**
     * Only when `type` equals 'PACKAGE'
     */
    specialistId: String,
    coupon: String,
    /**
     * Only when `type` equals 'CONFERENCE'
     */
    quotaId: String
  },
  components: {
    CheckoutReviews,
    CheckOutBenefit,
    CheckOutWaterMark,
    CheckOutPaymentMethod,
    CheckOutCoupon,
    CheckOutPackage,
    CheckOutInvoice,
    CheckOutModals,
    PackageCard,
    AppointmentCard,
    Skeleton,
    PageContainer,
    Heading,
    ConferenceAppointmentCard,
    ProgramAppointmentCard,
    Checkbox,
    CheckOutBanner
  },
  data () {
    const actions = {
      close: {
        icon: 'icon-close',
        callback: () => {
          PaymentEvent.closeCheckout({
            checkout: this.checkOut,
            user: this.user
          })

          if (this.type === 'PROGRAM') {
            this.$router.replace({
              name: 'ProgramDetail',
              params: {
                id: this.id
              }
            })
          } else if (this.type === 'PACKAGE') {
            if (this.$route.query.back) {
              this.$router.replace({
                path: this.$route.query.back
              })
            } else {
              this.$router.go(-1)
            }
          } else if (this.type === 'CONFERENCE') {
            this.currentModal = 'CANCEL_CONFERENCE'
          } else {
            if (this.$route.query.pay) {
              this.$router.replace({
                name: 'AppointmentDetails',
                param: this.id
              })
            } else {
              this.openModalCancel()
            }
          }
        }
      },
      toggleDetail: {
        icon: '',
        text: '',
        callback: () => null
      },
      submit: {
        text: this.$translations.checkout.submit,
        loading: false,
        callback: () => this.submit(),
        disabled: true
      }
    }

    if (this.type === 'APPOINTMENT' && !this.$route.query.pay) {
      actions.toggleDetail.text = this.$translations.checkout.appointment.edit
      actions.toggleDetail.icon = ''
      actions.toggleDetail.callback = () => this.cancelAppointment()
    }

    return {
      checkOut: null,
      modifiedInvoice: null,
      loading: {
        checkOut: true
      },
      actions,
      collapsed: {
        detail: false
      },
      texts: {
        APPOINTMENT: {
          detail: this.$translations.checkout.appointment.title,
          detailDefault:
            this.$translations.checkout.appointment['subtitle-default'],
          subtitle: this.$translations.checkout.appointment.subtitle,
          subtitleTimer:
            this.$translations.checkout.appointment['subtitle-timer']
        },
        PACKAGE: {
          detail: this.$translations.checkout.package,
          subtitle: this.$translations.checkout.program.subtitle
        },
        CONFERENCE: {
          detail: this.$translations.checkout.conference,
          subtitle: this.$translations.checkout.program.subtitle
        },
        PROGRAM: {
          detail: this.$translations.checkout.program.title,
          subtitle: this.$translations.checkout.program.subtitle
        }
      },
      /**
       * One of:
       * - LOADING
       * - LOADING_NEQUI
       * - LOADING_ADDI_RESULT
       * - LOADING_ADDI_URL
       * - ERROR
       * - CANCEL_APPOINTMENT
       * - SUCCESS_APPOINTMENT
       * - SUCCESS_PACKAGE
       * - SUCCESS_NUTRITION_APPOINTMENT
       * - SUCCESS_NUTRITION_PACKAGE
       * - CANCEL_CONFERENCE
       * - SUCCESS_CONFERENCE
       * - TIMEOUT_CONFERENCE
       */
      currentModal: null,
      currentPaymentMethod: null,
      currentCoupon: null,
      currentCouponPromocodeId: null,
      nextQueryParams: null,
      transactionPollCounter: 0,
      segmentEvent: null,
      showWompiCheck: false,
      timer: null,
      checkOutVersion: null,
      userBalance: null
    }
  },
  mounted () {
    this.getIntegration()

    if (this.type === 'CONFERENCE') {
      this.resetTimer()
    }

    if (this.specialistId) {
      this.nextQueryParams = {
        specialistId: this.specialistId
      }
    }

    if (this.quotaId) {
      this.nextQueryParams = {
        quotaId: this.quotaId
      }
    }
    window.onbeforeunload = () => {
      this.handleUserLeaving()
    }
  },
  methods: {
    toggleCredits (event) {
      CheckOutV2.toggleCredits(this.type)
        .then((response) => {
          this.handleInvoiceChange(response)
          if (this.checkOut.invoice.total < this.checkOut.credits) {
            if (event.isChecked) {
              this.actions.submit.text =
                this.$translations.checkout.credits['pay-button']
            } else {
              this.setSubmitText()
            }
          }
        })
        .catch(() => {
          this.$toast({
            severity: 'error',
            text: this.$translations.checkout.credits.error
          })
        })
    },
    callSegmentEvent () {
      const body = {
        user: this.user
      }
      SpecialistCard.Card_Schedule_Appointment_Successful(body)
    },
    async getIntegration () {
      this.loading.checkOut = true
      this.checkOutVersion = 2
      this.getCheckOutV2()
    },
    getCheckOut () {
      CheckOut.get(this.type, this.id, this.specialistId, this.quotaId)
        .then((checkOut) => {
          this.handleCheckOutSuccess(checkOut)
        })
        .catch((error) => {
          this.handleCheckOutError(error)
        })
        .finally(() => {
          this.loading.checkOut = false
        })
    },
    getCheckOutV2 () {
      const body = {
        product_id: this.id,
        product_type: this.type,
        specialist_id: this.specialistId,
        quota_id: this.quotaId
      }

      if (this.$route.query.origin === 'shared-account') {
        CheckOutSharedAccount.summary(body, this.sharedAccountId)
          .then((checkOut) => {
            this.handleCheckOutSuccess(checkOut)
          })
          .catch((error) => {
            this.handleCheckOutError(error)
          })
          .finally(() => {
            this.loading.checkOut = false
          })
        return
      }

      CheckOutV2.summary(body)
        .then((checkOut) => {
          this.handleCheckOutSuccess(checkOut)
        })
        .catch((error) => {
          this.handleCheckOutError(error)
        })
        .finally(() => {
          this.loading.checkOut = false
        })
    },
    handleCheckOutSuccess (checkOut) {
      this.checkOut = checkOut

      PaymentEvent.viewCheckout({
        checkout: this.checkOut,
        user: this.user
      })

      this.userBalance = CurrencyFilter(
        this.checkOut.credits / 100,
        this.checkOut.currency.toUpperCase()
      )

      if (this.checkOut.invoice?.has_package) {
        this.actions.submit.text =
          this.$translations.checkout['submit-with-package']
      } else if (this.checkOut.invoice.has_full_benefit) {
        this.actions.submit.text =
          this.$translations.checkout['submit-with-full-discount']
      } else {
        this.setSubmitText()
      }

      this.disabledSubmit()
    },
    handleCheckOutError (error) {
      if (this.type === 'PROGRAM') {
        this.$router.replace({
          name: 'ProgramDetail',
          params: {
            id: this.id
          }
        })
        return
      }

      if (this.specialistId) {
        this.$router
          .replace({
            name: 'SpecialistDetail',
            params: {
              id: this.specialistId
            }
          })
          .then(() => {
            this.$toast({
              severity: 'error',
              text: error.message
            })
          })
      } else {
        this.$router
          .replace({
            name: 'Agenda'
          })
          .then(() => {
            this.$toast({
              severity: 'error',
              text: error.message
            })
          })
      }
    },
    handleInvoiceChange (data) {
      if (data && data.coupon) {
        this.currentCoupon = data.coupon
        this.currentCouponPromocodeId = data.promocodeId
        this.nextQueryParams = {
          coupon: data.coupon
        }
      } else {
        this.currentCoupon = null
        this.currentCouponPromocodeId = null
        this.nextQueryParams = {
          coupon: null
        }
      }

      this.modifiedInvoice = data && data.invoice ? data.invoice : null
      this.setSubmitText()
      this.disabledSubmit()
    },
    handleCurrentModalChanges (modal) {
      if (this.currentModal === 'ERROR') {
        this.getCheckOutV2()
      }
      this.currentModal = modal
    },
    handleGoToAddCard () {
      this.$router.push({
        name: 'AddCreditCard',
        query: {
          next: JSON.stringify({
            name: 'CheckOut',
            params: {
              id: this.id,
              type: this.type
            },
            query: {
              ...this.nextQueryParams,
              origin:
                this.$route.query.origin === 'specialist-card'
                  ? this.$route.query.origin
                  : null
            }
          })
        }
      })
    },
    handleSelectPaymentMethod (paymentMethod) {
      this.currentPaymentMethod = paymentMethod

      const allowedPaymentMethods = [
        'PSE',
        'CARD',
        'NEQUI',
        'BANCOLOMBIA_TRANSFER',
        'BANCOLOMBIA_BNPL'
      ]

      this.showWompiCheck =
        this.checkOut &&
        !this.checkOut.invoice?.has_package &&
        allowedPaymentMethods.includes(this.currentPaymentMethod?.type)

      this.disabledSubmit()

      if (this.showWompiCheck) {
        setTimeout(() => {
          document.getElementById('waterMark').scrollIntoView(
            {
              behavior: 'smooth'
            },
            100
          )
        })
      }
    },
    handleUserLeaving () {
      /**
       * TODO: handle user leaving the check out
       */
    },
    cancelConference () {
      Conferences.deletePlaceAtConference(this.quotaId).then()
    },
    submit () {
      this.actions.submit.loading = true
      this.currentModal = 'LOADING'

      if (this.currentPaymentMethod?.type === 'ADDI') {
        this.currentModal = 'LOADING_ADDI_URL'
      } else if (this.currentPaymentMethod?.type === 'NEQUI') {
        this.currentModal = 'LOADING_NEQUI'
      } else {
        this.currentModal = 'LOADING'
      }

      let total = null

      if (this.modifiedInvoice && this.modifiedInvoice.total === 0) {
        total = 0
      } else {
        total = this.modifiedInvoice?.total || this.checkOut.invoice.total
      }

      submit(
        this.checkOutVersion,
        this.user.id,
        this.checkOut,
        this.currentPaymentMethod || null,
        this.currentCoupon || null,
        this.currentCouponPromocodeId || null,
        this.platform,
        total,
        this.sharedAccountId,
        this.$route.query?.origin || null,
        this.initTransactionPulling,
        this.checkOutOnError
      )

      const user = this.user

      this.segmentEvent = {
        payment_method: this.currentPaymentMethod,
        coupon_code: this.currentCoupon,
        amount: total,
        appointment_id: this.checkOut.id,
        specialist_name:
          this.checkOut?.appointment?.specialist?.name ||
          this.checkOut?.package?.specialist?.name ||
          this.checkOut?.conferenceData?.conference?.conference_speakers[0]
            .specialist.user,
        patient_name: `${user.first_name} ${user.last_name}`,
        starts_at: this.checkOut?.appointment?.date || new Date(),
        day: this.$moment(
          this.checkOut?.appointment?.date || new Date()
        ).format('DD/MM/YYYY'),
        time: this.$moment(
          this.checkOut?.appointment?.date || new Date()
        ).format('HH:mm'),
        service_name:
          this.checkOut?.appointment?.service?.name ||
          this.checkOut?.package?.name ||
          this.checkOut?.conferenceData.conference.name,
        status: 'PENDING',
        type: this.checkOut.type
      }

      PaymentEvent.clickPayOrder({
        paymentData: this.segmentEvent,
        user: this.user
      })
    },
    checkOutOnError (e) {
      if (e.message === 'ERROR_APPOINTMENT_RESERVED') {
        this.actions.submit.loading = false
        this.currentModal = 'ERROR_EXPIRED'
        return
      }

      this.actions.submit.loading = false
      this.currentModal = 'ERROR'
    },
    async checkOutFinished () {
      await this.$store.dispatch('auth/fetchUser')

      if (this.$route.query.origin === 'specialist-card') {
        this.callSegmentEvent()
      }
    },
    initTransactionPulling (transactionId) {
      if (this.isFreeOrHasFullDiscount()) {
        if (this.checkOut.type === 'APPOINTMENT') {
          this.currentModal = 'SUCCESS_APPOINTMENT'
        } else if (this.checkOut.type === 'PACKAGE') {
          this.currentModal = 'SUCCESS_PACKAGE'
        }

        this.checkOutFinished()

        return
      }

      if (this.currentModal === 'LOADING_ADDI_URL') {
        this.currentModal = 'LOADING_ADDI_RESULT'
      }

      this.actions.submit.loading = false
      this.trackTransactionChanges(transactionId)
    },
    trackTransactionChanges (transactionId) {
      if (this.transactionPollCounter === 100) {
        /**
         * TODO: handle max transaction polling
         */
        return
      }

      let timeout = 2000

      if (this.transactionPollCounter === 0) {
        timeout = 0
      }

      setTimeout(async () => {
        const transaction = await this.getTransaction(transactionId)
        const currency =
          transaction.payment_response?.currency?.toLowerCase() ||
          transaction.payment_response?.data?.currency?.toLowerCase()

        const transactionEvent = {
          ...transaction,
          product_type: this.type,
          service_type: this.checkOut?.appointment?.service?.type || '',
          product_id: this.id,
          currency
        }

        if (
          transaction?.payment_response?.status === 'requires_action' &&
          transaction?.payment_response?.client_secret
        ) {
          return await this.handleStripeAction(
            transaction?.payment_response?.client_secret,
            transactionEvent
          )
        }

        switch (transaction.status) {
          case 'PENDING':
          case 'PROCESSING_PAYMENT':
            this.transactionPollCounter++
            this.trackTransactionChanges(transactionId)
            break
          case 'APPROVED':
            this.resolveTransactionApproved(transactionEvent)
            break
          case 'DECLINED':
          case 'VOIDED':
          case 'ERROR':
          case 'REJECTED':
            this.resolveTransactionWithError(transactionEvent)
            break
          default:
            console.error('PACKAGE_CHECKOUT_NO_TRANSACTION_STATUS')
        }
      }, timeout)
    },
    async handleStripeAction (clientSecret, transactionEvent) {
      const stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY);

      const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret);

      if (error) {
        // Handle initial payment confirmation error
        return this.resolveTransactionWithError(transactionEvent);
      }

      switch (paymentIntent.status) {
        case 'succeeded':
          // Payment was successful
          return this.resolveTransactionApproved(transactionEvent);

        case 'requires_action': {
          // Handle next action (3DS authentication)
          const { error: handleError, paymentIntent: handlePaymentIntent } = await stripe.handleCardAction(clientSecret);

          if (handleError) {
            // Handle 3DS authentication error
            return this.resolveTransactionWithError(transactionEvent);
          }

          if (handlePaymentIntent.status ===  'succeeded') {
            // Payment was successful after handling action
            return this.resolveTransactionApproved(transactionEvent);
          } else {
            // Handle any other unexpected status
            return this.resolveTransactionWithError(transactionEvent);
          }
        }

        default:
          // Handle any other unexpected status
          return this.resolveTransactionWithError(transactionEvent);
      }
    },
    getTransaction (id) {
      let transactionService = TransactionsV2

      if (this.checkOutVersion !== 2) {
        transactionService = Transactions
      }

      return transactionService.getById(id).catch(() => {
        /**
         * TODO handle transaction get error
         */
      })
    },
    toggleDetail () {
      if (this.type === 'APPOINTMENT') {
        return
      }

      this.actions.toggleDetail.icon = this.collapsed.detail
        ? 'icon-arrow-small-outline-down'
        : 'icon-arrow-small-outline-up'
      this.collapsed.detail = !this.collapsed.detail
    },
    setSubmitText () {
      let priceFormatted = 0

      if (this.modifiedInvoice && this.modifiedInvoice.total === 0) {
        priceFormatted = CurrencyFilter(0 / 100, this.currency)
      } else {
        priceFormatted = CurrencyFilter(
          (this.modifiedInvoice?.total || this.checkOut.invoice.total) / 100,
          this.currency
        )
      }

      const pay = this.$translations.checkout.submit

      if (this.type === 'PROGRAM') {
        this.actions.submit.text = this.$translations.checkout.program.submit
        return
      }

      this.actions.submit.text = `${pay} ${priceFormatted}`
    },
    isFreeOrHasFullDiscount () {
      return (
        this.checkOut.invoice.has_package ||
        this.checkOut.invoice.has_full_benefit ||
        (this.modifiedInvoice && this.modifiedInvoice.total === 0) ||
        this.checkOut.invoice.total === 0
      )
    },
    disabledSubmit () {
      if (!this.checkOut) {
        return
      }

      if (this.isFreeOrHasFullDiscount()) {
        this.actions.submit.disabled = false
        return
      }

      if (this.currentPaymentMethod?.type === 'COBRE') {
        this.actions.submit.disabled = Boolean(
          !this.currentPaymentMethod.document ||
            !this.currentPaymentMethod.document_type
        )
        return
      }

      this.actions.submit.disabled = Boolean(
        !this.currentPaymentMethod?.value &&
          !this.currentPaymentMethod?.user_legal_id &&
          !this.currentPaymentMethod?.place_id
      )
    },
    resetTimer () {
      this.handleCurrentModalChanges('')

      this.timer = setTimeout(() => {
        this.handleCurrentModalChanges('TIMEOUT_CONFERENCE')
      }, 300000)
    },
    navigateToSpecialistProfile () {
      const specialists = this.checkOut.package.specialists.map(
        (specialist) => {
          return {
            ...specialist,
            specialities: specialist.specialities.map((speciality) => {
              return {
                speciality: {
                  name: speciality[`name_${this.lang}`]
                }
              }
            })
          }
        }
      )

      this.$router.push({
        name: 'SpecialistsList',
        query: {
          title: this.$translations.programs.detail.specialists.available,
          specialists: JSON.stringify(specialists),
          back: '/'
        }
      })
    },
    cancelAppointment () {
      Appointments.cancelAppointment(this.id)
        .then(() => {
          this.$router.replace({
            name: 'Schedule',
            query: {
              specialistId: this.specialistId,
              back: `specialists/${this.specialistId}`
            }
          })
        })
        .catch((error) => {
          this.$toast({
            text: error.message,
            severity: 'error'
          })
        })
    },
    openModalCancel () {
      this.$globalEvent.$emit('modal/cancel', {
        showDialog: {
          cancel: {
            open: true,
            close: false
          }
        },
        appointment: {
          id: this.id,
          serviceType: this.checkOut.appointment.service.type,
          redirect: 'Home'
        }
      })
    },
    resolveTransactionApproved (transactionEvent) {
      this.currentModal =
        this.checkOut?.appointment?.service?.type === 'NUTRITION'
          ? `SUCCESS_${this.type}_NUTRITION`
          : `SUCCESS_${this.type}`
      this.checkOutFinished()

      if (this.user.status !== 'ACTIVE') {
        PaymentEvent.successfulFirstPayment({
          paymentData: transactionEvent,
          user: this.user
        })
      }

      PaymentEvent.successfulPayment({
        paymentData: transactionEvent,
        user: this.user
      })
    },
    resolveTransactionWithError (transactionEvent) {
      this.currentModal = 'ERROR'
      PaymentEvent.failedPayment({
        paymentData: transactionEvent,
        user: this.user
      })
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      role: 'auth/role',
      timerAppointment: 'timer/get',
      interval: 'timer/interval',
      timezone: 'userTimezone',
      platform: 'platform',
      userMember: 'sharedAccount/user',
      sharedAccountId: 'sharedAccount/sharedAccountId'
    }),
    checkOutModalSpecialist () {
      if (!this.checkOut) {
        return null
      }

      if (this.type === 'CONFERENCE') {
        return (
          this.checkOut &&
          this.checkOut?.conferenceData?.conference?.conference_speakers[0]?.id
        )
      }

      return this.type === 'APPOINTMENT'
        ? this.checkOut.appointment.specialist
        : this.checkOut.package.specialist
    },
    specialistCardImage () {
      if (!this.checkOut) {
        return ''
      }

      if (this.type === 'PACKAGE') {
        return this.checkOut.package.specialist.picture
      }

      return this.checkOut.appointment.specialist.picture
    },
    specialistCardIcon () {
      if (!this.checkOut) {
        return ''
      }
      return this.checkOut.appointment.service.icon_name
    },
    specialistCardTitle () {
      if (!this.checkOut) {
        return ''
      }

      if (this.type === 'PACKAGE') {
        return this.checkOut.package.specialist.name
      }

      return this.checkOut.appointment.specialist.name
    },
    specialistCardDate () {
      if (!this.checkOut) {
        return ''
      }

      const date = this.$moment(this.checkOut.appointment.date)
        .tz(this.timezone)
        .format('DD MMMM, ddd • h:mm a')
      const duration = `${this.checkOut.appointment.service.duration} (${this.$translations.time['minutes-sort']})`

      return `${date} ${duration}`
    },
    specialistCardService () {
      if (!this.checkOut) {
        return ''
      }

      const modality =
        this.$translations.appointments.virtual[
          this.checkOut.appointment.virtual.toString()
        ]

      return `${modality} • ${this.checkOut.appointment.service.name}`
    },
    packageCardData () {
      if (this.type !== 'PACKAGE' || !this.checkOut) {
        return null
      }

      const packageData = this.checkOut.package

      return {
        name: packageData.name,
        discount: packageData.discount,
        price: packageData.final_price,
        product_packages: [
          {
            max_uses: packageData.max_uses,
            price: {
              total: packageData.original_price
            },
            name: packageData.service
          }
        ],
        icon_name: packageData.icon_name
      }
    },
    invoice () {
      return this.modifiedInvoice || this.checkOut?.invoice || null
    },
    currency () {
      return this.checkOut?.currency?.toUpperCase() || ''
    },
    paymentMethods () {
      if (!this.checkOut) {
        return null
      }

      return this.checkOut.payment_methods
    },
    showPaymentMethods () {
      if (!this.checkOut) {
        return false
      }

      return !this.isFreeOrHasFullDiscount()
    },
    showCouponSection () {
      if (this.checkOut && this.checkOut.type === 'CONFERENCE') {
        return false
      }

      if (this.checkOut && !this.coupon && this.checkOut.invoice.total === 0) {
        return false
      }

      return (
        this.checkOut &&
        !this.checkOut.benefit &&
        !this.checkOut.invoice.has_package
      )
    },
    totalBalance () {
      const text = this.$translations.checkout.credits.description
      return text.replaceAll('{balance}', this.userBalance)
    },
    showCredits () {
      return (
        this.checkOut &&
        !this.checkOut.benefit &&
        !this.checkOut.invoice.has_package &&
        this.checkOut.credits > 0
      )
    },
    creditsUse () {
      const creditsToUse =
        this.checkOut.invoice.total < this.checkOut.credits
          ? this.checkOut.invoice.total
          : this.checkOut.credits
      const text = CurrencyFilter(
        creditsToUse / 100,
        this.checkOut.currency.toUpperCase()
      )
      return text
    },
    detailText () {
      if (this.$route.query.pay) {
        return this.texts[this.type].detailDefault
      }

      return this.texts[this.type].subtitle
    }
  },
  beforeDestroy () {
    this.handleUserLeaving('destroy')
    clearTimeout(this.timer)
  }
}
</script>

<style lang="sass">
.segment .subtitle
  color: var(--gray-60)

.subtitle-segment
  margin-bottom: -16px

.balance
  &-available
    display: flex
    justify-content: space-between
  &-checkbox
    flex-direction: row-reverse
    .body
      font-weight: 700
      color: var(--gray-60)
</style>
