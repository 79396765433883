<template>
  <AppointmentCard
    :image="image"
    :title="title"
    :middle-line="middle"
    :bottom-line="firstLine"
    v-if="programData"
  />
</template>

<script>
import { AppointmentCard } from '@seliaco/red-panda'

export default {
  name: 'ProgramAppointmentCard',
  props: {
    program: Object
  },
  components: {
    AppointmentCard
  },
  data () {
    const lang = this.$store.getters.language.lang
    return {
      specialists: null,
      programData: null,
      lang
    }
  },
  mounted () {
    this.mapperProgram()
  },
  methods: {
    mapperProgram () {
      let name = this.program.name
      let subtitle = this.program.meta.subtitle
      let modality = this.program.meta.modality
      let duration = this.program.meta.duration
      let content = this.program.content
      let programContent = this.program.meta.program_content
      let description = this.program.description

      if (this.lang !== 'es') {
        name = this.program[`name_${this.lang}`]
        subtitle = this.program.meta[`subtitle_${this.lang}`]
        modality = this.program.meta[`modality_${this.lang}`]
        duration = this.program.meta[`duration_${this.lang}`]
        content = this.program[`content_${this.lang}`]
        programContent = this.program.meta[`program_content_${this.lang}`]
        description = this.program[`description_${this.lang}`]
      }

      this.programData = {
        id: this.program.id,
        price: this.program.price,
        currency: this.program.product_packages[0].price.currency,
        name,
        subtitle,
        description,
        content,
        discount: this.program.discount,
        duration: duration,
        modality: modality,
        programContent,
        image: this.program.meta.image,
        session: this.program.product_packages[0].max_uses.toString()
      }

      this.specialists = this.program.specialists.map((specialist) => {
        return {
          ...specialist,
          specialities: specialist.specialities.map((speciality) => {
            return {
              speciality: {
                name: speciality[`name_${this.lang}`]
              }
            }
          })
        }
      })
    }
  },
  computed: {
    image () {
      return this.programData.image
    },
    title () {
      return `<div class="content-title"> ${this.programData.name} </div>`
    },
    middle () {
      return `<div class="content-title margin-bottom-xs"> ${this.programData.subtitle} </div>`
    },
    firstLine () {
      return `${this.programData.duration} • ${this.programData.session} ${this.$translations.programs.detail.stats.sessions} • ${this.programData.modality}`
    }
  }
}
</script>

<style lang="sass" scoped>
::v-deep .appointment-card
  grid-template-columns: auto
  &-content
    grid-template-columns: 70px auto 24px
    gap: 0px 8px
  &-content-image
    border-radius: 16px
    width: 64px
    height: 64px
</style>
